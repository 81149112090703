import { createStyles, makeStyles, Theme } from '@material-ui/core';
export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        contentMobile: {
            marginTop: '10%',
            padding: '16px !important',
            minHeight: '362px',
        },
        content: {
            padding: '16px !important',
            minHeight: '362px',
        },
        valueInput: {
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
        },
        duration: {
            '@media and (max-width: 767px)': {
                marginTop: '5%',
                display: 'flex',
                alignItems: 'center',
            },
            '@media and (min-width: 768px)': {
                display: 'flex',
                alignItems: 'center',
            },
        },
        grow: {
            flexGrow: 1,
        },
        input: {
            marginRight: '5%',
        },
    })
);
import {
    FinancialHealthIcon,
    FinancialIndependenceIcon,
    FlowIcon,
    IncomingDestinationIcon,
    HomeworkIcon,
    IconProps,
    InvestimentsIcon,
    RiskManagementIcon,
} from './assets/icons';
import FinancialHealth from './components/customerPages/financialHealth/financialHealth/financialHealth';
import FinancialIndependence from './components/customerPages/financialIndependence/financialIndependence/financialIndependence';
import Flow from './components/customerPages/flow/flow/flow';
import Homework from './components/customerPages/homework/homework/homework';
import Investments from './components/customerPages/investments/investments/investments';
import RiskManagement from './components/customerPages/riskManagement/riskManagement/riskManagement';
import { ICustomerPageProps } from './components/customerPages/types';
import IncomingDestination from './components/customerPages/incomingDestination/incomingDestination/incomingDestination';
import { MenuMobile } from './components/planningForm/planningResult/menuMobile';

export interface IPageDescriptor {
    name: string;
    url: string;
    Component: React.FunctionComponent<ICustomerPageProps>;
    Icon: React.FunctionComponent<IconProps>;
}

const menuOptions: IPageDescriptor[] = [
    {
        name: 'Seu Número',
        url: '/financialhealth',
        Component: FinancialHealth,
        Icon: FinancialHealthIcon,
    },
    {
        name: 'Linha do tempo',
        url: '/flow',
        Component: Flow,
        Icon: FlowIcon,
    },
    {
        name: 'Independência Financeira',
        url: '/financialindependence',
        Component: FinancialIndependence,
        Icon: FinancialIndependenceIcon,
    },
    {
        name: 'Carteira & Investimentos',
        url: '/investments',
        Component: Investments,
        Icon: InvestimentsIcon,
    },
    {
        name: 'Destino da Renda',
        url: '/incomedst',
        Component: IncomingDestination,
        Icon: IncomingDestinationIcon,
    },
    {
        name: 'Seguros e Previdência',
        url: '/riskmanagement',
        Component: RiskManagement,
        Icon: RiskManagementIcon,
    },
    {
        name: 'Meu Progresso',
        url: '/homework',
        Component: Homework,
        Icon: HomeworkIcon,
    },
];

export default menuOptions;

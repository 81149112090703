import { Grid, MenuItem, OutlinedInput, Paper, Select, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip, Typography, withStyles, } from '@material-ui/core';
import React from 'react';
import formatter from '../../../global/formater';
import { insurance_categories } from '../../../planningForm/riskManagement/insurance/insurance';
import { IContracted } from '../types';
import { useStyles } from './style'

const NoBorderCell = withStyles({
    root: {
        borderBottom: 'none',
        fontSize: 16,
    },
})(TableCell);

interface IInsurancesProps extends IContracted { }

const Insurances: React.FC<IInsurancesProps> = ({
    contractedInsurances,
    paidYearly,
    dedicatedPercentage,
    patrimonyPercentage,
}) => {
    const classes = useStyles();
    const [monthly, setMonthly] = React.useState(false);
    const handleChange = (
        event: React.ChangeEvent<{
            value: unknown;
        }>
    ) => {
        const isMonthly = event.target.value === 1 ? true : false;
        setMonthly(isMonthly);
    };
    return (
        <>
            {window.screen.width <= 767 ? (
                <div className='container d-column'>
                    <div>
                        <h5 className='fw-bold mt-5'>Quanto você dedica para a sua proteção atualmente</h5>
                    </div>
                    <Grid item justify="flex-end">
                        <Select
                            labelId="monthly-selector"
                            id="monthly-select"
                            color="primary"
                            input={<OutlinedInput classes={{ input: classes.input }} />}
                            value={monthly ? 1 : 0}
                            defaultValue={1}
                            onChange={handleChange}
                            style={{ width: '150px' }}
                        >
                            {/* create an entry for each profile type and filter all null entrys */}
                            <MenuItem value={1}>Mensal</MenuItem>
                            <MenuItem value={0}>Anual</MenuItem>
                        </Select>
                    </Grid>
                    <div>
                        <div
                            className={classes.displayContainer}
                            style={
                                contractedInsurances.length < 4
                                    ? { justifyContent: 'space-between', height: '100%' }
                                    : { justifyContent: 'flex-start' }
                            }
                        >
                            <h6 className='fw-bold mt-5'>Seu investimento anual é de</h6>
                            <div className={classes.paid}>
                                <Typography variant="h4" color="secondary" style={{ marginRight: 8 }}>
                                    {formatter.format(paidYearly)}
                                </Typography>
                                <Typography>para proteção</Typography>
                            </div>
                            <Typography variant="h6">Equivalente a</Typography>
                            <Paper className={classes.infoCard}>
                                <div className={classes.dedicationDisplay}>
                                    <Typography variant="h5" color="secondary">
                                        {dedicatedPercentage.toFixed(2).replace('.', ',')} %
                                    </Typography>
                                    <Typography>das suas sobras</Typography>
                                </div>
                            </Paper>
                            <div className={classes.separator}>
                                <div className={classes.border} />
                                <span className={classes.dividerText}>e</span>
                                <div className={classes.border} />
                            </div>
                            <Paper className={classes.infoCard}>
                                <div className={classes.dedicationDisplay}>
                                    <Typography variant="h5" color="secondary">
                                        {patrimonyPercentage.toFixed(2).replace('.', ',')} %
                                    </Typography>
                                    <Typography>do seu patrimônio</Typography>
                                </div>
                            </Paper>
                        </div>
                    </div>

                    {contractedInsurances.length ? (
                        <div>
                            <TableContainer
                                style={contractedInsurances.length < 3 ? { height: '100%', overflowY: 'hidden' } : undefined}
                                component={props => <Paper {...props} />}
                            >
                                <Table
                                    style={
                                        contractedInsurances.length < 3 ? { height: '100%', overflowY: 'hidden' } : undefined
                                    }
                                    aria-label="tabela de seguros"
                                >
                                    <TableHead>
                                        <TableRow>
                                            <NoBorderCell>Categoria</NoBorderCell>
                                            <NoBorderCell align="right">{monthly ? 'Mensalidade' : 'Anualidade'}</NoBorderCell>
                                            <NoBorderCell align="right">Prazo</NoBorderCell>
                                            <NoBorderCell align="right">Benefício</NoBorderCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {contractedInsurances.map((item, index) => (
                                            <TableRow key={item.category + index}>
                                                <NoBorderCell>
                                                    <Tooltip title={item.name}>
                                                        <span>{insurance_categories.get(item.category) || item.category}</span>
                                                    </Tooltip>
                                                </NoBorderCell>
                                                <NoBorderCell align="right">
                                                    {formatter.format(item.monthlyPay * (monthly ? 1 : 12))}
                                                </NoBorderCell>
                                                <NoBorderCell align="right">{item.term} anos</NoBorderCell>
                                                <NoBorderCell align="right">{formatter.format(item.assured)}</NoBorderCell>
                                            </TableRow>
                                        ))}
                                        <TableRow>
                                            <NoBorderCell align="right" colSpan={3} style={{ fontWeight: 'bold' }}>
                                                Total pago
                                            </NoBorderCell>
                                            <NoBorderCell align="right">
                                                {formatter.format(monthly ? paidYearly / 12 : paidYearly)}
                                            </NoBorderCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </div>
                    ) : null}
                </div>
            ) : (
                <div className="">
                    <Grid container spacing={4}>
                        <Grid item container justify="space-between" alignItems="center" xs={12}>
                            <Grid item xs={8}>
                                <Typography variant="h5">Quanto você dedica para a sua proteção atualmente</Typography>
                            </Grid>
                            <Grid item justify="flex-end">
                                <Select
                                    labelId="monthly-selector"
                                    id="monthly-select"
                                    color="primary"
                                    input={<OutlinedInput classes={{ input: classes.input }} />}
                                    value={monthly ? 1 : 0}
                                    defaultValue={1}
                                    onChange={handleChange}
                                    style={{ width: '150px' }}
                                >
                                    {/* create an entry for each profile type and filter all null entrys */}
                                    <MenuItem value={1}>Mensal</MenuItem>
                                    <MenuItem value={0}>Anual</MenuItem>
                                </Select>
                            </Grid>
                        </Grid>
                        <Grid container direction="column" item xs={4}>
                            <div
                                className={classes.displayContainer}
                                style={
                                    contractedInsurances.length < 4
                                        ? { justifyContent: 'space-between', height: '100%' }
                                        : { justifyContent: 'flex-start' }
                                }
                            >
                                <Typography variant="h6">Seu investimento anual é de</Typography>
                                <div className={classes.paid}>
                                    <Typography variant="h4" color="secondary" style={{ marginRight: 8 }}>
                                        {formatter.format(paidYearly)}
                                    </Typography>
                                    <Typography>para proteção</Typography>
                                </div>
                                <Typography variant="h6">Equivalente a</Typography>
                                <Paper className={classes.infoCard}>
                                    <div className={classes.dedicationDisplay}>
                                        <Typography variant="h5" color="secondary">
                                            {dedicatedPercentage.toFixed(2).replace('.', ',')} %
                                        </Typography>
                                        <Typography>das suas sobras</Typography>
                                    </div>
                                </Paper>
                                <div className={classes.separator}>
                                    <div className={classes.border} />
                                    <span className={classes.dividerText}>e</span>
                                    <div className={classes.border} />
                                </div>
                                <Paper className={classes.infoCard}>
                                    <div className={classes.dedicationDisplay}>
                                        <Typography variant="h5" color="secondary">
                                            {patrimonyPercentage.toFixed(2).replace('.', ',')} %
                                        </Typography>
                                        <Typography>do seu patrimônio</Typography>
                                    </div>
                                </Paper>
                            </div>
                        </Grid>

                        {contractedInsurances.length ? (
                            <Grid item xs={8}>
                                <TableContainer
                                    style={contractedInsurances.length < 3 ? { height: '100%', overflowY: 'hidden' } : undefined}
                                    component={props => <Paper {...props} />}
                                >
                                    <Table
                                        style={
                                            contractedInsurances.length < 3 ? { height: '100%', overflowY: 'hidden' } : undefined
                                        }
                                        aria-label="tabela de seguros"
                                    >
                                        <TableHead>
                                            <TableRow>
                                                <NoBorderCell>Categoria</NoBorderCell>
                                                <NoBorderCell align="right">{monthly ? 'Mensalidade' : 'Anualidade'}</NoBorderCell>
                                                <NoBorderCell align="right">Prazo</NoBorderCell>
                                                <NoBorderCell align="right">Benefício</NoBorderCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {contractedInsurances.map((item, index) => (
                                                <TableRow key={item.category + index}>
                                                    <NoBorderCell>
                                                        <Tooltip title={item.name}>
                                                            <span>{insurance_categories.get(item.category) || item.category}</span>
                                                        </Tooltip>
                                                    </NoBorderCell>
                                                    <NoBorderCell align="right">
                                                        {formatter.format(item.monthlyPay * (monthly ? 1 : 12))}
                                                    </NoBorderCell>
                                                    <NoBorderCell align="right">{item.term} anos</NoBorderCell>
                                                    <NoBorderCell align="right">{formatter.format(item.assured)}</NoBorderCell>
                                                </TableRow>
                                            ))}
                                            <TableRow>
                                                <NoBorderCell align="right" colSpan={3} style={{ fontWeight: 'bold' }}>
                                                    Total pago
                                                </NoBorderCell>
                                                <NoBorderCell align="right">
                                                    {formatter.format(monthly ? paidYearly / 12 : paidYearly)}
                                                </NoBorderCell>
                                            </TableRow>
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Grid>
                        ) : null}
                    </Grid>
                </div>
            )}

        </>
    );
};
export default Insurances;

import { createStyles, makeStyles, Theme } from '@material-ui/core';
import { grey } from '@material-ui/core/colors';

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            marginBottom: theme.spacing(2),
        },
        content: {
            '@media (max-width: 767px)':{
                marginTop: theme.spacing(1),
                background: theme.palette.primary.dark,
                maxHeight: 127,
            },
            '@media (min-width: 768px)':{
                marginTop: theme.spacing(1),
                background: theme.palette.primary.dark,
                maxHeight: 127,
                width: '50%',
            },
        },
        flexLine: {
            '@media (max-width: 767px)':{
                display: 'flex',
                padding: theme.spacing(1),
                paddingLeft: theme.spacing(2),
                justifyContent: 'space-between',
            },
            '@media (min-width: 768px)':{
                margin: '15px 0 10px',
                display: 'flex',
                padding: theme.spacing(1),
                paddingRight: theme.spacing(6),
                paddingLeft: theme.spacing(2),
                justifyContent: 'space-between',
                alignItems: 'baseline',
            },
        },
        whiteLabel: {
            color: 'white',
        },
        valueLabel: {
            color: theme.palette.success.main,
            marginRight: theme.spacing(1),
        },
        dotLine: {
            paddingLeft: theme.spacing(1),
            paddingBottom: theme.spacing(1),
        },
        dotRoot: {
            border: 'none',
            backgroundColor: 'transparent',
            cursor: 'pointer',
            padding: 0,
            '&:active': {
                opacity: 1,
            },
            '&:disabled': {
                opacity: 0.3,
            },
        },
        dot: {
            boxShadow: '1px 1px 2px rgba(0,0,0,0.9)',
            borderRadius: '60%',
            width: 8,
            height: 8,
            margin: 3,
            backgroundColor: 'white',
        },
        buttonBack: {
            transform: 'translateY(-200%)',
            backgroundColor: 'transparent',
            float: 'right',
            marginLeft: '-15px',
            border: 'none',
            color: 'white',
            '&:disabled': {
                color: grey[600],
            },
        },
    })
);

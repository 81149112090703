import { Divider, InputAdornment, MenuItem, Paper, Select, Typography, useTheme, } from '@material-ui/core';
import throttle from 'lodash.throttle';
import React from 'react';
import { useDispatch } from 'react-redux';
import api from '../../../../apis/dreamrichapi';
import { ChangeFinancialIndependenceChart } from '../../../../redux/actions/customerActions';
import unmaskCurrency from '../../../../utils/unmaskCurrency';
import TwoLineDisplay from '../../../global/displays/twoLine/twoLine';
import formatter from '../../../global/formater';
import { RectangularInput } from '../../../global/inputs/RectangularInput';
import CurrencyInput from '../../../global/masks/currencyMaskedInput';
import { defaultProfileGain, IFinancialInfo, IProfileGain } from '../../../toolbarInfo/types';
import Duration from '../duration/duration';
import { IFinancialIndependence } from '../types';
import { useStyles } from './style'

interface IPatrimonyTotal { className?: string; elevation: number; flowRentability: number; patrimonyAtIndependence: number; longlifeWithdraw: number; plannedWithdraw: number; defaultWithdraw: number; independenceYear: number; userID?: string; defaultDuration: number; }

const PatrimonyTotal: React.FC<IPatrimonyTotal> = ({
    className,
    elevation,
    flowRentability,
    patrimonyAtIndependence,
    plannedWithdraw,
    longlifeWithdraw,
    independenceYear,
    defaultWithdraw,
    userID,
    defaultDuration,
}) => {
    const classes = useStyles();
    const theme = useTheme();

    const dispatch = useDispatch();

    const [profileGain, setProfileGain] = React.useState<IProfileGain>(defaultProfileGain);
    const [rate, setRate] = React.useState<number>(flowRentability);
    const [showOther, setShowOther] = React.useState(false);

    const [desiredWithdraw, setDesiredWithdraw] = React.useState('');
    const [desiredValue, setDesiredValue] = React.useState(0);

    const inputRef = React.useRef<any | null>(null);
    const [buttonHeight, setButtonHeight] = React.useState(0);

    const [duration, setDuration] = React.useState(defaultDuration);

    const [lastTimeout, setLastTimeout] = React.useState<any>();
    const [err, setErr] = React.useState('');
    const [loading, setLoading] = React.useState(false);


    const getFinancialIndependence = async (withdrawSubmit: string) => {
        setLoading(true);

        if (!(flowRentability && rate)) return;

        try {
            const url = `/financialindependence?`;
            const params = new URLSearchParams();

            if (userID) {
                params.append('userID', userID);
            }

            if (withdrawSubmit) {
                setDesiredValue(unmaskCurrency(withdrawSubmit));
                params.append('withdraw', unmaskCurrency(withdrawSubmit).toString());
            }

            if (flowRentability) {
                params.append('rentability', flowRentability.toString());
            }

            if (rate) {
                params.append('afterIndependence', rate.toString());
            }

            const response = (await api.get<IFinancialIndependence>(url + params.toString())).data;
            setDuration(response.duration);
            dispatch(ChangeFinancialIndependenceChart(response.projection));
            setErr('');
        } catch (e) {
            if (e.response) {
                setErr(e.response.data);
            }
        }

        setLoading(false);
    };

    const getThrottled = throttle(getFinancialIndependence, 1000);

    const onChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        if (!event.target.value) {
            // reset withdraw
            getFinancialIndependence('');
            setDesiredWithdraw('');
            setDesiredValue(0);
            return;
        }

        const rawNum = unmaskCurrency(event.target.value).toString();

        if (lastTimeout) {
            clearTimeout(lastTimeout);
        }
        const to = setTimeout(() => {
            getThrottled(rawNum);
        }, 300);

        setLastTimeout(to);
        setDesiredWithdraw(rawNum);
    };

    React.useEffect(() => {
        api.get<IFinancialInfo>('/financialinfo')
            .then(response => {
                setProfileGain(response.data.profileGain);
                let isRegisteredRate = false;
                Object.entries(response.data.profileGain).forEach(([key, val]) => {
                    if (val === flowRentability) {
                        isRegisteredRate = true;
                    }
                });
                if (!isRegisteredRate) {
                    setShowOther(true);
                }
            })
            .catch(e => {
                console.debug(e);
            });
    }, []);

    React.useEffect(() => {
        setDesiredValue(defaultWithdraw);
    }, [defaultWithdraw, setDesiredValue]);


    React.useEffect(() => {
        setButtonHeight(inputRef.current.offsetHeight);
    }, []);



    React.useEffect(() => {
        getFinancialIndependence(desiredWithdraw);
    }, [rate, setDuration, setLoading]);

    return (
        <div className={className}>
            <div className='container'>
                <div className={classes.display}>
                    <Paper className={classes.rootContent} style={{ flexGrow: 1 }} elevation={elevation}>
                        <Typography variant="h6" className={classes.header}>Patrimônio total em {independenceYear}</Typography>
                        <TwoLineDisplay
                            className={classes.child}
                            label={'Líquido'}
                            value={formatter.format(patrimonyAtIndependence)}
                            valueColor={theme.palette.primary.dark}
                        />
                    </Paper>
                    {window.screen.width <= 767 ? (
                        <Paper className={classes.rootContent} elevation={elevation}>
                            <div style={{ alignContent: 'baseline' }} className={classes.line}>
                                <Typography variant="h6">Saques</Typography>
                                <Typography
                                    style={{ lineHeight: '2.8', marginLeft: theme.spacing(1) }}
                                    variant="overline"
                                    align="right"
                                >
                                    Juros real {((flowRentability || 0.04) * 100).toFixed(2)}% a.a
                                </Typography>
                            </div>

                            <div className={classes.line}>
                                {defaultDuration !== -1 && (
                                    // when patrimony is too high for withdraw show only longlife withdraw
                                    <TwoLineDisplay
                                        className={classes.child}
                                        label={`Por ${defaultDuration} anos`}
                                        value={formatter.format(defaultWithdraw)}
                                        valueColor={theme.palette.secondary.light}
                                    />
                                )}
                                {defaultDuration !== -1 && (
                                    <Divider className={classes.divider} flexItem orientation="vertical" />
                                )}
                                <TwoLineDisplay
                                    className={classes.child}
                                    label="Vitalício"
                                    value={formatter.format(longlifeWithdraw)}
                                    valueColor={theme.palette.secondary.light}
                                />
                            </div>
                        </Paper>
                    ) : (
                        <Paper style={{ margin: '0 16px' }} className={classes.rootContent} elevation={elevation}>
                            <div style={{ alignContent: 'baseline' }} className={classes.line}>
                                <Typography variant="h6">Saques</Typography>
                                <Typography
                                    style={{ lineHeight: '2.8', marginLeft: theme.spacing(1) }}
                                    variant="overline"
                                    align="right"
                                >
                                    Juros real {((flowRentability || 0.04) * 100).toFixed(2)}% a.a
                                </Typography>
                            </div>

                            <div className={classes.line}>
                                {defaultDuration !== -1 && (
                                    // when patrimony is too high for withdraw show only longlife withdraw
                                    <TwoLineDisplay
                                        className={classes.child}
                                        label={`Por ${defaultDuration} anos`}
                                        value={formatter.format(defaultWithdraw)}
                                        valueColor={theme.palette.secondary.light}
                                    />
                                )}
                                {defaultDuration !== -1 && (
                                    <Divider className={classes.divider} flexItem orientation="vertical" />
                                )}
                                <TwoLineDisplay
                                    className={classes.child}
                                    label="Vitalício"
                                    value={formatter.format(longlifeWithdraw)}
                                    valueColor={theme.palette.secondary.light}
                                />
                            </div>
                        </Paper>
                    )}

                    <Paper className={classes.rootLined} style={{ flexGrow: 1 }} elevation={elevation}>
                        <div style={{ flexGrow: 1 }} className={classes.rootContent}>
                            <Typography variant="h6" className={classes.header}>Saque Desejado e Simulação</Typography>
                            <div className={classes.spacedLine}>
                                <div style={{ width: 150 }}>
                                    <TwoLineDisplay
                                        className={classes.child}
                                        label="Valor desejado"
                                        value={formatter.format(desiredValue)}
                                        valueColor={theme.palette.success.main}
                                    />
                                </div>
                                <Divider className={classes.divider} flexItem orientation="vertical" />
                                <div className={classes.child}>
                                    <Typography className={classes.heading} align="left" variant="body1">Juros</Typography>
                                    <Select
                                        labelId="fi-rate-selector"
                                        id="fi-rate-selector"
                                        value={rate}
                                        style={{ minWidth: '50px' }}
                                        fullWidth
                                        onChange={e => setRate(e.target.value as number)}
                                    >
                                        <MenuItem className={classes.menuItem} value={profileGain.default}>
                                            <Typography className={classes.selector}>{~~(profileGain.default * 100)}%</Typography>
                                        </MenuItem>

                                        <MenuItem className={classes.menuItem} value={profileGain.conservative}>
                                            <Typography className={classes.selector}>{~~(profileGain.conservative * 100)}%</Typography>
                                        </MenuItem>

                                        <MenuItem className={classes.menuItem} value={profileGain.moderate}>
                                            <Typography className={classes.selector}>{~~(profileGain.moderate * 100)}%</Typography>
                                        </MenuItem>

                                        <MenuItem className={classes.menuItem} value={profileGain.bold}>
                                            <Typography className={classes.selector}>{~~(profileGain.bold * 100)}%</Typography>
                                        </MenuItem>

                                        <MenuItem className={classes.menuItem} value={profileGain.agressive}>
                                            <Typography className={classes.selector}>{~~(profileGain.agressive * 100)}%</Typography>
                                        </MenuItem>
                                        {showOther && (
                                            <MenuItem className={classes.menuItem} value={flowRentability}>{~~(flowRentability * 100)}%</MenuItem>
                                        )}
                                    </Select>
                                </div>

                                <Divider className={classes.divider} flexItem orientation="vertical" />

                                <div className={classes.withdraw}>
                                    <RectangularInput
                                        type="numeric"
                                        variant="outlined"
                                        label="Novo saque"
                                        placeholder="simule"
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        InputProps={{
                                            inputComponent: CurrencyInput as any,
                                            startAdornment: (
                                                <InputAdornment style={{ marginTop: 0 }} position="start">R$</InputAdornment>
                                            ),
                                        }}
                                        error={err ? true : false}
                                        helperText={err}
                                        value={desiredWithdraw}
                                        onChange={onChange}
                                        style={{ maxWidth: 130 }}
                                        ref={inputRef}
                                        onKeyPress={e => e.key === 'Enter' ? getFinancialIndependence(desiredWithdraw) : null}
                                    />
                                </div>
                            </div>
                        </div>
                        <Paper className={classes.darkPaper}><Duration elevation={elevation} loading={loading} duration={duration} /></Paper>
                    </Paper>
                </div>
            </div>
        </div>
    );
};
export default PatrimonyTotal;

import { createStyles, makeStyles, Theme } from '@material-ui/core';
export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            '@media (max-width: 767px)':{
                marginTop: '5rem !important',
            },
            '@media (min-width: 768px)':{
                padding: theme.spacing(0, 4, 4, 4),
                marginTop: '5rem !important',
            }
        },
        header: {
            display: 'flex',
            justifyContent: 'flex-start',
            alignItems: 'center',
            marginLeft: theme.spacing(1),
        },
        heading: {
            color: '#2C2F31',
            fontWeight: 600,
            paddingLeft: '5px',
            marginBottom: '1px',
        },
        grow: {
            flexGrow: 1,
        },
    })
);
import { Grid, Paper, Typography } from '@material-ui/core';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Cell, Pie, PieChart, ResponsiveContainer, Sector } from 'recharts';
import { RoundIcon } from '../../../../../assets/icons';
import { SetHomeworkPortfolioMap, setRentability } from '../../../../../redux/actions/customerActions';
import { IStore } from '../../../../../redux/types/store';
import ValueColorText from '../../../../global/displays/valueColorText/valueColorText';
import formatter from '../../../../global/formater';
import { IFinancialAssets } from '../../../../planningForm/patrimony/types';
import { IPortfolioMap, ISummaryEntry } from '../../types';
import { useStyles, colors } from '../style'

interface IDataChart { name: string; value: number; yield: number; }

const renderActiveShape = (data: IDataChart[]) => (props: any) => {
    const { cx, cy, innerRadius, outerRadius, startAngle, endAngle, fill, payload, percent } = props;

    return (
        <g>
            <text x={cx} y={cy} dy={8} textAnchor="middle" fill="#000000">{data.filter(item => item.name === payload.name)[0].yield.toFixed(2)} %</text>

            <Sector
                cx={cx}
                cy={cy}
                innerRadius={innerRadius}
                outerRadius={outerRadius}
                startAngle={startAngle}
                endAngle={endAngle}
                fill={fill}
            />
            <Sector
                cx={cx}
                cy={cy}
                startAngle={startAngle}
                endAngle={endAngle}
                innerRadius={outerRadius + 6}
                outerRadius={outerRadius + 10}
                fill={fill}
            />
        </g>
    );
};

interface IExpensesProps {
    portfolio: IPortfolioMap;
    suggested?: boolean;
    summary: ISummaryEntry;
    dataHeight: number;
    controlFunction?: (pieChart: boolean, investmentProfile: number) => void;
    approach?: number;
}

const InvestmentPortfolioMobile: React.FC<IExpensesProps> = ({
    portfolio,
    suggested,
    summary,
    dataHeight,
}) => {
    const classes = useStyles();
    const [currentIdx, setIdx] = React.useState(-1);
    const [hoverStyle, setHoverStyle] = React.useState('');
    const [data, setData] = React.useState<IDataChart[]>([]);
    const [total, setTotal] = React.useState(0);

    const dispatch = useDispatch();

    const { currSelectedLabel, suggSelectedLabel } = useSelector((store: IStore) => ({
        currSelectedLabel: store.customer.investimentPage.porfolioSummary.current.classLabel,
        suggSelectedLabel: store.customer.investimentPage.porfolioSummary.suggested.classLabel,
    }));

    React.useEffect(() => {
        const mineLabel = suggested ? suggSelectedLabel : currSelectedLabel;
        const otherLabel = suggested ? currSelectedLabel : suggSelectedLabel;

        if (mineLabel === '' && mineLabel !== otherLabel) {
            const portfolioEntry = portfolio.get(otherLabel);
            if (portfolioEntry) {
                const financialAssets = portfolioEntry.financialAssets;
                dispatch(SetHomeworkPortfolioMap(!suggested, otherLabel, financialAssets));
            }
        }
    }, [currSelectedLabel, suggSelectedLabel, dispatch, portfolio]);

    const hover = (dataIdx: number) => {
        setIdx(dataIdx);
        setHoverStyle(classes.labelHover);
    };

    React.useEffect(() => {
        const sum = (investments: IPortfolioMap) => {
            return [...investments.values()].reduce((prev, curr) => prev + curr.value, 0);
        };

        const convertInvestmentToDataChart = (investments: IPortfolioMap, total_: number): IDataChart[] => {
            const dataChart: IDataChart[] = Array.from(investments, ([className, value]) => ({
                name: className,
                ...value,
                yield: (value.value / total_) * 100,
            }))
                .filter(item => item.name)
                .sort((a, b) => a.yield - b.yield);
            return dataChart;
        };

        if (portfolio) {
            const newPort = convertInvestmentToDataChart(portfolio, sum(portfolio));
            const reduced = newPort.reduce((acc, curr) => acc + curr.value, 0);
            setData(newPort);
            setTotal(reduced);
        }
    }, [portfolio, setData, setTotal, setRentability]);



    const onLabelClick = (className: string) => {
        let financialAssets: IFinancialAssets[] = [];
        const portfolioEntry = portfolio.get(className);
        if (portfolioEntry) {
            financialAssets = portfolioEntry.financialAssets;
        }

        dispatch(SetHomeworkPortfolioMap(!suggested, className, financialAssets));
    };

    return (
        <>
                <Paper className={classes.root} elevation={1}>
                    <div>
                        <div>
                            <Typography className={classes.cardHeader} gutterBottom>
                                Carteira {!suggested ? 'Atual' : 'Sugerida'}
                            </Typography>
                        </div>
                        <div className={`${classes.chartContainer} d-column`}>
                            <ResponsiveContainer height="100%">
                                <PieChart width={300} height={200}>
                                    <Pie
                                        activeIndex={currentIdx}
                                        activeShape={renderActiveShape(data)}
                                        data={data}
                                        isAnimationActive={true}
                                        dataKey="value"
                                        nameKey="name"
                                        cx="50%"
                                        cy="50%"
                                        startAngle={90}
                                        endAngle={450}
                                        innerRadius={50}
                                        outerRadius={100}
                                        fill="#8884d8"
                                        onMouseEnter={(_, index) => setIdx(index)}
                                        onMouseLeave={() => setIdx(-1)}
                                        onClick={props => onLabelClick(props.name)}
                                    >
                                        {colors.map(entry => (
                                            <Cell fill={entry} />
                                        ))}
                                    </Pie>
                                </PieChart>
                            </ResponsiveContainer>
                        </div>
                        <div>
                            <div>
                                <Typography align="left">Classe de ativo</Typography>
                            </div>
                            <div>
                                <Typography align="right">Patrimônio</Typography>
                            </div>
                            <div>
                                <Typography align="right">% alocado</Typography>
                            </div>
                        </div>
                        <div>
                            {data
                                .map((item, idx) => (
                                    <React.Fragment>
                                        <div><RoundIcon color={colors[idx]} width={10} height={10} /></div>
                                        <div>
                                            {/* @ts-ignore */}
                                            <div onMouseEnter={() => hover(idx)} onClick={() => onLabelClick(item.name)}>
                                                <Typography
                                                    className={currentIdx === idx ? hoverStyle : ''}
                                                    style={{ fontSize: 14, textTransform: 'capitalize' }}
                                                >
                                                    {item.name}
                                                </Typography>
                                            </div>
                                        </div>
                                        <div>
                                            {/* @ts-ignore */}
                                            <div onMouseEnter={() => hover(idx)} onClick={() => onLabelClick(item.name)}>
                                                <Typography
                                                    className={classes.labelValue}
                                                    color="primary"
                                                    align="right"
                                                    style={{ fontSize: 14, fontWeight: currentIdx === idx ? 600 : 400 }}
                                                >
                                                    {formatter.format(item.value)}
                                                </Typography>
                                            </div>
                                        </div>
                                        <div>
                                            <div onMouseEnter={() => hover(idx)}>
                                                <Typography
                                                    className={classes.labelValue}
                                                    color="primary"
                                                    align="right"
                                                    style={{ fontSize: 14, fontWeight: currentIdx === idx ? 600 : 400 }}
                                                >
                                                    {item.yield?.toFixed(2)}%
                                                </Typography>
                                            </div>
                                        </div>
                                    </React.Fragment>
                                ))
                                .reverse()}
                        </div>
                        <div><Typography variant="subtitle1">*Clique na classe de ativos para detalhar</Typography></div>
                        <div />
                        <div>
                            <div><Typography align="right">Rentabilidade da carteira</Typography></div>
                            <div>
                                <ValueColorText
                                    sameColor
                                    style={{ fontSize: 14 }}
                                    align="right"
                                    value={summary.rentability}
                                    type="percentage"
                                >
                                    a.a.
                                </ValueColorText>
                            </div>
                        </div>
                        <div />
                        <div >
                            <div><Typography align="right">% do CDI</Typography></div>
                            <div>
                                <ValueColorText
                                    sameColor
                                    style={{ fontSize: 14 }}
                                    type="percentage"
                                    value={summary.rentabilityCDI}
                                    align="right"
                                >
                                    a.a.
                                </ValueColorText>
                            </div>
                        </div>
                        <div />
                        <div>
                            <div><Typography align="right">Ganho real da carteira</Typography></div>
                            <div>
                                <ValueColorText
                                    sameColor
                                    style={{ fontSize: 14 }}
                                    align="right"
                                    type="percentage"
                                    value={summary.realGain}
                                >
                                    a.a.
                                </ValueColorText>
                            </div>
                        </div>
                        <div>
                            <Paper className={suggested ? classes.suggested : classes.total}>
                                <div className={classes.totalLabel}>
                                    <>
                                        <Typography style={{ fontSize: 16 }}>Total investido</Typography>
                                    </>
                                    <Typography
                                        color="secondary"
                                        className={suggested ? classes.suggestedText : ''}
                                        style={{ fontSize: 18, fontWeight: 600 }} >
                                        {formatter.format(total)}
                                    </Typography>
                                </div>
                            </Paper>
                        </div>
                    </div>
                </Paper>
            
        </>

    );
};

export default InvestmentPortfolioMobile;
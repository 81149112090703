import { createStyles, makeStyles, Theme } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            padding: theme.spacing(0, 2, 0, 2),
            marginBottom: '30px',
        },
        subHeading: {
            color: '#2C2F31',
            fontWeight: 600,
            marginBottom: theme.spacing(2),
        },
        cardHeader: {
            fontWeight: 600,
            fontSize: 16,
            color: '#020202',
        },
        cardLine: {
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'wrap',
            justifyContent: 'center',
            alignContent: 'center',
            alignItems: 'center',
            margin: '-4px',
        },
        cardItem: {
            height: '100%',
            margin: 4,
            flexGrow: 1,
            borderRadius: '8px',
            justifyContent: 'center',
            alignContent: 'center',
            alignItems: 'center',
        },
        spacing: {
            padding: theme.spacing(1),
        },
        lastCard: {
            maxWidth: '30vw',
        },
        successTextColor: {
            color: theme.palette.success.main,
        },
        errorTextColor: {
            color: theme.palette.error.main,
        },
    })
);
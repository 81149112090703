import { createStyles, makeStyles, Theme, Typography } from '@material-ui/core';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import api from '../../../../apis/dreamrichapi';
import { ChangeCustomerFixedCost } from '../../../../redux/actions/planningActions';
import { IStore } from '../../../../redux/types/store';
import { IPlannerPageProps } from '../../types';
import Changes from '../changes/changes';
import EducationalCost from '../educationalCost/educationalCost';
import HabitationalCost from '../habitationalCost/habitationalCost';
import OtherCost from '../otherCost/otherCost';
import PersonalCost from '../personalCost/personalCost';
import TransportCost from '../transportCost/transportCost';
import { useStyles } from './style';
import {
    ICostChange,
    IEducationalValues,
    IFixedCost,
    IHabitationalValues,
    IOtherValues,
    IPersonalValues,
    ITransportValues,
    IWellbeingValues,
} from '../types';
import WellbeingCost from '../wellbeingCost/wellbeingCost';

const FixedCost: React.FC<IPlannerPageProps> = ({ changeTitle }) => {
    const { nickname, fixedCostStored, userID, currToolbar } = useSelector((store: IStore) => ({
        nickname: store.planning.customerUserInfo.nickname,
        fixedCostStored: store.planning.customerFormValues.fixedCost,
        userID: store.planning.customerUserInfo.userID,
        currToolbar: store.planning.toolbar.current,
    }));

    const fixedCostStorage = JSON.parse(localStorage.getItem('storageCustomer'+userID))?.fixedCost;

    const history = useHistory();
    if (!userID) {
        history.push('/');
    }

    const classes = useStyles();

    const [currentStep, setStep] = React.useState(1);
    React.useEffect(() => {
        if (!currToolbar || currentStep === currToolbar) return;
        setStep(currToolbar);
    }, [currToolbar]);

    const nextStep = () => {
        if (currentStep < totalSteps) {
            setStep(currentStep + 1);
        }
    };

    const [send, setSend] = React.useState(false);
    const finish = () => {
        setReady(true);
        // finish dipares send effect bellow
        setSend(true);
    };

    const [fixedCost, setFixedCost] = React.useState<IFixedCost>(fixedCostStored);
    const [ready, setReady] = React.useState(false);
    const [errLabel, setErrLabel] = React.useState('');
    const [loading, setLoading] = React.useState(false);
    const dispatch = useDispatch();
    const [sum, setSum] = React.useState(0);

    React.useEffect(() => {
        if (send && fixedCost?.ready) {
            setLoading(true);
            const fixedCostBody: IFixedCost = { ...fixedCost, userID };
            delete fixedCostBody.ready;

            api.post('/fixedcost', fixedCostBody)
                .then(() => {
                    setLoading(false);
                    setErrLabel('');
                    dispatch(ChangeCustomerFixedCost(fixedCost));
                    history.push('/planner');
                })
                .catch(err => {
                    setLoading(false);
                    if (err.response) {
                        setErrLabel(`Erro salvando informações de custo fixo: ${err.response.data}`);
                    } else {
                        setErrLabel(`Erro interno do sistema: ${err}`);
                    }
                });
            setSend(false);
        }
    }, [fixedCost, history, nickname, send]);

    const previousStep = () => {
        if (currentStep > 0) {
            setStep(currentStep - 1);
        }
    };

    const goBack = () => {
        history.push('/planner');
    };

    const [habitationalValues, setHabitationalValues] = React.useState<IHabitationalValues>(fixedCostStorage?.habitational || fixedCost.habitational);
    const [personalValues, setPersonalValues] = React.useState<IPersonalValues>(
        {
            care: fixedCostStorage?.care,
            feeding: fixedCostStorage?.feeding,
        }
        ||
        {
            care: fixedCost.care,
            feeding: fixedCost.feeding,
        }
    );
    const [wellBeingValues, setWellbeingValues] = React.useState<IWellbeingValues>(
        {
        health: fixedCostStorage?.health,
        recreation: fixedCostStorage?.recreation,
        }
        ||
        {
        health: fixedCost.health,
        recreation: fixedCost.recreation,
        }
    );
    const [otherValues, setOtherValues] = React.useState<IOtherValues>(fixedCostStorage?.other||fixedCost.other);
    const [educationalValues, setEducationalValues] = React.useState<IEducationalValues>(fixedCostStorage?.education|| fixedCost.education);
    const [transportValues, setTransportValues] = React.useState<ITransportValues>(fixedCostStorage?.transport|| fixedCost.transport);
    const [changes, setChanges] = React.useState<ICostChange[]>(fixedCostStorage?.costChange || fixedCost.costChange || []);
    const Steps = [
        <HabitationalCost
            setHabitationalValues={setHabitationalValues}
            nextStep={nextStep}
            previousStep={goBack}
            defaultHabitationalValues={habitationalValues}
        />,
        <PersonalCost
            setPersonalValues={setPersonalValues}
            nextStep={nextStep}
            previousStep={previousStep}
            defaultPersonalValues={personalValues}
        />,
        <WellbeingCost
            setWellbeingValues={setWellbeingValues}
            nextStep={nextStep}
            previousStep={previousStep}
            defaultWellbeingValues={wellBeingValues}
        />,
        <EducationalCost
            defaultEducationalValues={educationalValues}
            setEducationalValues={setEducationalValues}
            nextStep={nextStep}
            previousStep={previousStep}
        />,
        <TransportCost
            defaultTransportValues={transportValues}
            setTransportValues={setTransportValues}
            nextStep={nextStep}
            previousStep={previousStep}
        />,
        <OtherCost
            setOtherValues={setOtherValues}
            defaultOtherValues={otherValues}
            nextStep={nextStep}
            previousStep={previousStep}
            errLabel={errLabel}
            loading={loading}
            total={sum}
        />,
        <Changes changes={changes} setChanges={setChanges} nextStep={finish} previousStep={previousStep} />,
    ];

    const totalSteps = Steps.length;

    React.useEffect(() => {
        if (habitationalValues) {
            setFixedCost(previous => ({ ...previous!, habitational: { ...habitationalValues } }));
        }
        if (personalValues) {
            setFixedCost(previous => ({
                ...previous!,
                care: { ...personalValues.care },
                feeding: { ...personalValues.feeding },
                ready,
            }));
        }
        if (wellBeingValues) {
            setFixedCost(previous => ({
                ...previous!,
                recreation: { ...wellBeingValues.recreation },
                health: { ...wellBeingValues.health },
                ready,
            }));
        }
        if (educationalValues) {
            setFixedCost(previous => ({ ...previous!, education: educationalValues, ready }));
        }
        if (transportValues) {
            setFixedCost(previous => ({ ...previous!, transport: transportValues, ready }));
        }
        if (otherValues) {
            setFixedCost(previous => ({ ...previous!, other: otherValues, ready }));
        }
        if (changes) {
            setFixedCost(previous => ({ ...previous!, costChange: [...changes], ready }));
        }
        const lsum: number = Object.entries(fixedCost)
            .filter(([key]) => key !== 'other' && key !== 'costChange')
            .map(([_, val]) => val)
            .reduce((previous, currentValue) => {
                let totalObject = 0;
                if (typeof currentValue === 'object') {
                    //@ts-ignore
                    totalObject = Object.values(currentValue).reduce((prev: number, curr) => {
                        if (typeof curr === 'number') {
                            return prev + curr;
                        }
                        return prev;
                    }, 0);
                }
                return previous + totalObject;
            }, 0);
        setSum(lsum);
    }, [
        habitationalValues,
        educationalValues,
        transportValues,
        personalValues,
        wellBeingValues,
        otherValues,
        changes,
        currentStep,
        ready,
    ]);

    React.useEffect(() => {
        changeTitle(`Custo Fixo`, currentStep, totalSteps);
    }, [currentStep, totalSteps]);

    return (
        <div className={classes.content}>
            {/* <LinearProgress variant="determinate" value={percentage} color="primary" /> */}
            <Typography variant="h4" align="center" className={classes.header}>
                Seu custo fixo é o retrato do seu padrão de vida
            </Typography>
            {Steps[currentStep - 1]}
        </div>
    );
};
export default FixedCost;

import { blueGrey } from '@material-ui/core/colors';
import { makeStyles, Theme, createStyles, Paper, Typography } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        rootContent: {
            '@media (max-width: 767px)':{
                marginTop: '3rem !important',
                padding: '12px'
            },
            '@media (min-width: 768px)':{
                padding: theme.spacing(2),
            }
        },
        header: {
            color: blueGrey[900],
            marginBottom: theme.spacing(2),
        },
    })
);
import { createStyles, makeStyles, Theme } from '@material-ui/core';
import { grey } from '@material-ui/core/colors';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import api from '../../../../apis/dreamrichapi';
import { ChangeCustomerFinancing } from '../../../../redux/actions/planningActions';
import { IStore } from '../../../../redux/types/store';
import { IPlannerPageProps } from '../../types';
import Consortium from '../consortium/consortium';
import Financing from '../financing/financing';
import { IConsortium, IFinancingRequest, IFinancingValue } from '../types';
import {useStyles} from './style'

const FinancingPage: React.FC<IPlannerPageProps> = ({ changeTitle }) => {
    const classes = useStyles();
    const history = useHistory();

    const { defaultFinancing, nickname, userID, currToolbar } = useSelector((store: IStore) => ({
        defaultFinancing: store.planning.customerFormValues.financing,
        nickname: store.planning.customerUserInfo.nickname,
        userID: store.planning.customerUserInfo.userID,
        currToolbar: store.planning.toolbar.current,
    }));

    if (!userID) {
        history.push('/');
    }

    console.log(defaultFinancing)

    const financingStorage = JSON.parse(localStorage.getItem("financingStorage"))

    const [currentStep, setStep] = React.useState(1);
    React.useEffect(() => {
        if (!currToolbar || currentStep === currToolbar) return;
        setStep(currToolbar);
    }, [currToolbar]);

    const nextStep = () => {
        if (currentStep < totalSteps) {
            setStep(currentStep + 1);
        }
    };


    const [send, setSend] = React.useState(false);
    const [ready, setReady] = React.useState(false);
    const finish = () => {
        setReady(true);
        
        setSend(true);
    };

    const [loading, setLoading] = React.useState(false);

    const [financingreq, setFinancingReq] = React.useState<IFinancingRequest>(defaultFinancing);
    const [errLabel, setErrLabel] = React.useState('');
    
    useEffect(()=> {
        setFinancingReq(defaultFinancing)
    }, [defaultFinancing])


    const dispatch = useDispatch();
    React.useEffect(() => {
  
        if (send && financingreq?.ready) {
            const financingRequest: IFinancingRequest = { ...financingreq, userID };
            delete financingRequest.ready;
            api.post('/financing ', financingRequest)
                .then(() => {
                    setLoading(false);
                    history.push('/planner');
                    setErrLabel('');
                    dispatch(ChangeCustomerFinancing(financingreq));
                    localStorage.setItem('financingStorage', JSON.stringify(financingreq))
                })
                .catch(err => {
                    if (err.response) {
                        setErrLabel(`Erro salvando informações de independência financeira: ${err.response.data}`);
                    } else {
                        setErrLabel(`Erro interno do sistema: ${err}`);
                    }
                });
            setSend(false);
        }
    }, [send, history, financingreq]);

    const previousStep = () => {
        setStep(currentStep - 1);
    };

    const totalSteps = 2;

    const percentage = (currentStep / totalSteps) * 100;
    const [consortia, setConsortia] = React.useState<IConsortium[]>(financingreq.consortia || financingStorage?.consortia);
    const [financing, setFinancing] = React.useState<IFinancingValue[]>(financingreq.financing || financingStorage?.financing );
    const Steps: JSX.Element[] = [
        <Financing
            defaultFinancing={financing}
            consortia={consortia}
            setFinancingValues={setFinancing}
            nextStep={nextStep}
            previousStep={history.goBack}
            nickname={nickname}
        />,
        <Consortium
            defaultConsortia={consortia}
            setConsortia={setConsortia}
            previousStep={previousStep}
            nextStep={finish}
        />,
    ];

    React.useEffect(() => {
     
        if (financing) {
            setFinancingReq(previous => ({ ...previous!, financing, ready }));
        }
        if (consortia) {
            setFinancingReq(previous => ({ ...previous!, consortia, ready }));
        }
    }, [financing, consortia, ready]);

    React.useEffect(() => {
        changeTitle(`Financiamentos`, currentStep, totalSteps);
    }, [currentStep, totalSteps]);

    return <div className='container'>{Steps[currentStep - 1]}</div>;
};
export default FinancingPage;

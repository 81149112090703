import { Typography } from '@material-ui/core';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import api from '../../../../apis/dreamrichapi';
import { HomeworkIcon } from '../../../../assets/icons';
import { ChangeHomework, UpdateHomeworkYear } from '../../../../redux/actions/customerActions';
import { SelectCurrentPage } from '../../../../redux/actions/planningActions';
import { IStore } from '../../../../redux/types/store';
import { getGroup } from '../../../login/types';
import { IFinancialInfo } from '../../../toolbarInfo/types';
import { ICustomerPageProps } from '../../types';
import Considerations from '../considerations/considerations';
import FinancialIndependenceGoals from '../financialIndependenceGoal/financialIndependenceGoal';
import MonthlyGoals from '../monthlyGoals/monthlyGoals';
import { IHomework } from '../types';
import YearlyGoals from '../yearlyGoal/yearlyGoal';
import { useStyles } from './style';
import LoadingPage from '../../../global/loadingPage/desktop/loadingPage';

const Homework: React.FC<ICustomerPageProps> = ({ userID, pageNumber }) => {
    const { homework, rentability, loggedUser } = useSelector((store: IStore) => ({
        homework: store.customer.homework,
        rentability: store.customer.rentability,
        loggedUser: store.loggedUser.currentUser,
    }));

    const classes = useStyles();

    const dispatch = useDispatch();

    const [, isplanner] = getGroup(loggedUser);

    const [loading, setLoading] = React.useState(true);
    const [err, setErr] = React.useState('');

    const considerationsUpdate = async (considerations: string) => {
        try {
            await api.post('/updateconsiderations', { userID, considerations });
            return true;
        } catch (e) {
            console.debug(e);
            return false;
        }
    };

    const currentYear = new Date().getFullYear();
    let userIDCompare;

    React.useEffect(() => {
        dispatch(SelectCurrentPage(pageNumber));
    }, []);
    const [selectedYear, setSelectedYear] = React.useState<number>(0);
    React.useEffect(() => {
        setSelectedYear(currentYear - homework.planningYear || 0);
    }, [homework.planningYear, setSelectedYear]);

    const yearControl = (forward: boolean, year?: number) => {
        if (year) {
            setSelectedYear(year - homework.planningYear);
        } else {
            setSelectedYear(oldYear => {
                if (forward && oldYear < homework.yearlyGoals.length - 1) {
                    return ++oldYear;
                }
                if (!forward && oldYear > 0) {
                    return --oldYear;
                }
                return oldYear;
            });
        }
    };

    React.useEffect(() => {
        dispatch(UpdateHomeworkYear(selectedYear));
    }, [selectedYear]);

    const getDefaultRentability = async () => {
        try {
            const response = await api.get<IFinancialInfo>('/financialinfo');
            return response.data.profileGain.default;
        } catch (e) {
            console.error(e);
            return 0;
        }
    };

    const fetchYear = async (year?: number) => {

        userIDCompare = String(localStorage.getItem("userID"));

        if (localStorage.getItem("homework" + userIDCompare) != undefined || localStorage.getItem("homework" + userIDCompare) != null) {

            dispatch(ChangeHomework(JSON.parse(String(localStorage.getItem("homework" + userIDCompare)))));
            setErr('');
            setLoading(false);

            const params = new URLSearchParams();
            const url = `/homework?`;

            if (year) {
                params.append('year', year.toString());
            }
            if (userID) {
                params.append('userID', userID);
            }
            if (rentability) {
                params.append('rentability', rentability.toString());
            } else {
                const rentabilityValue = await getDefaultRentability();
                params.append('rentability', rentabilityValue.toString());
            }

            const response = (await api.get<IHomework>(url + params.toString())).data;
            
            if (String(localStorage.getItem("omework" + userIDCompare)) !== JSON.stringify(response)){
                localStorage.setItem("homework" + userIDCompare, JSON.stringify(response));
                dispatch(ChangeHomework(response));
                setErr('');
            }

        } else {

            const params = new URLSearchParams();
            const url = `/homework?`;

            if (year) {
                params.append('year', year.toString());
            }
            if (userID) {
                params.append('userID', userID);
            }
            if (rentability) {
                params.append('rentability', rentability.toString());
            } else {
                const rentabilityValue = await getDefaultRentability();
                params.append('rentability', rentabilityValue.toString());
            }

            const response = (await api.get<IHomework>(url + params.toString())).data;
            localStorage.setItem("homework" + userIDCompare, JSON.stringify(response));
            dispatch(ChangeHomework(response));

        }

        setLoading(false);
    };

    React.useEffect(() => {
        fetchYear();
    }, []);

    return (
        <LoadingPage loading={loading}>
            <div className={classes.root}>
                <div className={classes.header}>
                    <HomeworkIcon color="rgb(116, 116, 116)" />
                    <Typography className={classes.heading} align="left">
                        Dever de casa
                    </Typography>
                    <div className={classes.grow} />
                </div>
                <FinancialIndependenceGoals FIGoals={homework.financialIndependenceGoals} />
                <div>
                    <YearlyGoals
                        YGoals={homework.yearlyGoals}
                        fetchYear={fetchYear}
                        selectedYear={selectedYear}
                        yearControl={yearControl}
                        planningYear={homework.planningYear}
                        lastYear={homework.lastYear}
                    />
                    <MonthlyGoals
                        implementations={homework.yearlyGoals[selectedYear]?.implementations || []}
                        yearIndex={selectedYear}
                        MGoals={homework.yearlyGoals[selectedYear]?.monthlyGoals || []}
                        startYear={homework.minYear}
                        endYear={homework.maxYear}
                        fetchYear={fetchYear}
                        year={homework.yearlyGoals[selectedYear]?.year || currentYear}
                        userID={userID}
                        yearControl={yearControl}
                    />
                </div>
                <div>
                    <Considerations
                        onUpdate={considerationsUpdate}
                        customer={!isplanner}
                        defaultText={homework.considerationsText}
                    />
                </div>
                {err && <Typography>{err}</Typography>}
            </div>
        </LoadingPage>
    );
};
export default Homework;
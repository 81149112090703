import { createStyles, makeStyles, Theme } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            padding: theme.spacing(0, 4, 4, 4),
        },
        header: {
            '@media (max-width: 767px)': {
                marginTop: '6rem !important',
                display: 'flex',
                justifyContent: 'flex-start',
                alignItems: 'center',
                marginLeft: theme.spacing(1),
            },
            '@media (min-width: 768px)': {
                marginTop: '6rem !important',
                display: 'flex',
                justifyContent: 'flex-start',
                alignItems: 'center',
                marginLeft: theme.spacing(1),
            }
        },
        heading: {
            color: '#2C2F31',
            fontWeight: 600,
            paddingLeft: '5px',
            marginBottom: theme.spacing(1),
        },
        gridContainer: {
            marginBottom: theme.spacing(1),
        },
        flex: {
            display: 'flex',
        },
        grow: {
            flexGrow: 1,
        },
    })
);
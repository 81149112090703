import { Grid, Typography } from '@material-ui/core';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import api from '../../../../apis/dreamrichapi';
import { InvestimentsIcon } from '../../../../assets/icons';
import { ChangeInvestmentPortfolio } from '../../../../redux/actions/customerActions';
import { SelectCurrentPage } from '../../../../redux/actions/planningActions';
import { IStore } from '../../../../redux/types/store';
import LoadingPage from '../../../global/loadingPage/desktop/loadingPage';
import { IFinancialAssets, investimentLabel } from '../../../planningForm/patrimony/types';
import { ICustomerPageProps } from '../../types';
import InvestimentSummary from '../investimentSummary/investimentSummary';
import InvestmentProjection from '../investVsContr/investVsContr';
import InvestmentPortfolio from '../portfolio/desktop/portfolio';
import InvestmentPortfolioMobile from '../portfolio/mobile/portfolioMobile';
import PortfolioSummary from '../portolioSummary/portolioSummary';
import { IInvestmentPortfolio } from '../types';
import { useStyles } from './style';

const Investments: React.FC<ICustomerPageProps> = ({ userID, pageNumber }) => {
    const classes = useStyles();
    const { investmentPortfolio } = useSelector((store: IStore) => ({
        investmentPortfolio: store.customer.investmentPortfolio,
    }));
    const dispatch = useDispatch();
    const [loading, setLoading] = React.useState(false);

    let regex = /"/gi;
    const userIDStorage: string = String(localStorage.getItem('userID')).replace(regex, "");

    const getUserID = () => {
        if (!userIDStorage) return userID;
        else return userIDStorage;
    }

    const investPortfolioStorage = JSON.parse(localStorage.getItem('investments' + getUserID()));

    const [portfoliotype] = React.useState(
        new Map([
            [1, 0.02],
            [2, 0.04],
            [3, 0.06],
            [4, 0.08],

            [0.02, 1],
            [0.04, 1],
            [0.06, 3],
            [0.08, 1],
        ])
    );

    React.useEffect(() => {
        dispatch(SelectCurrentPage(pageNumber));
    }, []);

    const [additional, setAdditional] = React.useState(0);

    const changeParameters = async (pieChart: boolean, portfolioType: number, additionalValue?: number) => {

        if (localStorage.getItem("changeParameters" + getUserID()) != undefined || localStorage.getItem("changeParameters" + getUserID()) != null) {

            let selectApproach = portfoliotype.get(JSON.parse(String(localStorage.getItem("changeParameters" + getUserID()))).portfolioProjection.approach);
            JSON.parse(String(localStorage.getItem("changeParameters" + getUserID()))).portfolioProjection.approach = selectApproach!;

            dispatch(ChangeInvestmentPortfolio(JSON.parse(String(localStorage.getItem("changeParameters" + getUserID())))));
            setLoading(false);

            const params = new URLSearchParams();

            const url = `/wallet?`;
            if (pieChart && portfolioType) {
                params.append(
                    'portfolio',
                    portfoliotype.get(portfolioType)?.toString()
                );
            } else if (!pieChart && portfolioType) {
                params.append(
                    'projectionApproach',
                    portfoliotype.get(portfolioType)?.toString()
                );
                if (additionalValue) {
                    params.append(
                        'additionalValue',
                        additionalValue.toString()
                    );
                    setAdditional(additionalValue);
                }
            }
            if (getUserID()) {
                params.append('userID', getUserID());
            }

            const response = (await api.get<IInvestmentPortfolio>(url + params.toString())).data;
            selectApproach = portfoliotype.get(response.portfolioProjection.approach);

            response.portfolioProjection.approach = selectApproach!;

            if (String(localStorage.getItem("changeParameters" + getUserID())) !== JSON.stringify(response)) {
                localStorage.setItem("changeParameters" + getUserID(), JSON.stringify(response));
                dispatch(ChangeInvestmentPortfolio(response));
            }

        } else {

            const params = new URLSearchParams();

            const url = `/wallet?`;
            if (pieChart && portfolioType) {
                params.append(
                    'portfolio',
                    portfoliotype.get(portfolioType)!.toString()
                );
            } else if (!pieChart && portfolioType) {
                params.append(
                    'projectionApproach',
                    portfoliotype.get(portfolioType)!.toString()
                );
                if (additionalValue) {
                    params.append(
                        'additionalValue',
                        additionalValue.toString()
                    );
                    setAdditional(additionalValue);
                }
            }
            if (getUserID()) {
                params.append('userID', getUserID());
            }

            const response = (await api.get<IInvestmentPortfolio>(url + params.toString())).data;
            const selectApproach = portfoliotype.get(response.portfolioProjection.approach);

            response.portfolioProjection.approach = selectApproach!;
            localStorage.setItem("changeParameters" + getUserID(), JSON.stringify(response));
            dispatch(ChangeInvestmentPortfolio(response));
        }

        setLoading(false);
    };

    React.useEffect(() => {

        setLoading(true);
        const loadLocalStorage = async () => {

            if (localStorage.getItem("investments" + getUserID()) != undefined || localStorage.getItem("investments" + getUserID()) != null) {

                dispatch(ChangeInvestmentPortfolio(JSON.parse(String(localStorage.getItem("investments" + getUserID())))));
                setLoading(false);

                const params = new URLSearchParams();

                const url = `/wallet?`;
                if (getUserID()) {
                    params.append('userID', getUserID());
                }
                const response = (await api.get<IInvestmentPortfolio>(url + params.toString())).data;

                if (String(localStorage.getItem("investments" + getUserID())) !== JSON.stringify(response)) {
                    localStorage.setItem("investments" + getUserID(), JSON.stringify(response));
                    dispatch(ChangeInvestmentPortfolio(response));
                }

            } else {

                const params = new URLSearchParams();

                const url = `/wallet?`;
                if (getUserID()) {
                    params.append('userID', getUserID());
                }
                const response = (await api.get<IInvestmentPortfolio>(url + params.toString())).data;
                dispatch(ChangeInvestmentPortfolio(response));
                localStorage.setItem("investments" + getUserID(), JSON.stringify(response));
            }
            setLoading(false);
        };

        loadLocalStorage();

    }, []);

    const portfolio = (investments: IFinancialAssets[]) =>
        Object.values(investments).reduce((memo, curr) => {
            const classLabel = investimentLabel[curr.investimentClass];
            // there must be a class defined in investiment label
            const prevValue = memo.get(classLabel);
            if (classLabel) {
                if (prevValue) {
                    memo.set(classLabel, {
                        value: prevValue.value + curr.appliedValue,
                        yield: prevValue.yield + curr.rentability,
                        financialAssets: [...prevValue.financialAssets, curr],
                    });
                } else {
                    memo.set(classLabel, {
                        value: curr.appliedValue,
                        yield: curr.rentability,
                        financialAssets: [curr],
                    });
                }
            }
            return memo;
        }, new Map<string, { value: number; yield: number; financialAssets: IFinancialAssets[] }>());

    const dataHeight = () =>
        (Math.max(
            portfolio(investPortfolioStorage?.suggestedPortfolio || investmentPortfolio.suggestedPortfolio).size,
            portfolio(investPortfolioStorage?.portfolio || investmentPortfolio.portfolio).size
        ) +
            1) *
        31;


    const currentPortfolio = portfolio(investPortfolioStorage?.portfolio || investmentPortfolio.portfolio);
    const suggestedPortfolio = portfolio(investPortfolioStorage?.suggestedPortfolio || investmentPortfolio.suggestedPortfolio);

    const loadPortfolioSummary = () => {
        if (!investPortfolioStorage) return <PortfolioSummary {...investmentPortfolio.summary} />
        else return <PortfolioSummary {...investPortfolioStorage?.summary} />
    }

    return (
        <LoadingPage loading={loading}>
            <div className={classes.root}>
                <div className={classes.header}>
                    <InvestimentsIcon color="rgb(116, 116, 116)" />
                    <Typography className={classes.heading} align="left">
                        Carteira de Investimentos
                    </Typography>
                    <div className={classes.grow} />
                </div>
                {window.screen.width <= 767 ? (
                    <div className={classes.gridContainer}>
                        <div>
                            <InvestmentPortfolioMobile
                                summary={investPortfolioStorage?.summary.current || investmentPortfolio.summary.current}
                                portfolio={currentPortfolio}
                                dataHeight={dataHeight()}
                            />
                        </div>
                        <div>
                            <InvestmentPortfolioMobile
                                portfolio={portfolio(investPortfolioStorage?.suggestedPortfolio || investmentPortfolio.suggestedPortfolio)}
                                summary={investPortfolioStorage?.summary.suggested || investmentPortfolio.summary.suggested}
                                suggested
                                controlFunction={changeParameters}
                                approach={investPortfolioStorage?.suggestedPortfolioApproach || investmentPortfolio.suggestedPortfolioApproach}
                                dataHeight={dataHeight()}
                            />
                        </div>
                        <div>
                            <InvestimentSummary current />
                        </div>
                        <div>
                            <InvestimentSummary />
                        </div>
                    </div>
                ) : (
                    <Grid className={classes.gridContainer} container spacing={2}>
                        <Grid item xs={suggestedPortfolio.size === 0 ? 12 : 6} hidden={currentPortfolio.size === 0}>
                            <InvestmentPortfolio
                                summary={investPortfolioStorage?.summary.current || investmentPortfolio.summary.current}
                                portfolio={currentPortfolio}
                                dataHeight={dataHeight()}
                            />
                        </Grid>
                        <Grid item xs={currentPortfolio.size === 0 ? 12 : 6} hidden={suggestedPortfolio.size === 0}>
                            <InvestmentPortfolio
                                portfolio={portfolio(investPortfolioStorage?.suggestedPortfolio || investmentPortfolio.suggestedPortfolio)}
                                summary={investPortfolioStorage?.summary.suggested || investmentPortfolio.summary.suggested}
                                suggested
                                controlFunction={changeParameters}
                                approach={investPortfolioStorage?.suggestedPortfolioApproach || investmentPortfolio.suggestedPortfolioApproach}
                                dataHeight={dataHeight()}
                            />
                        </Grid>
                        <Grid item xs={suggestedPortfolio.size === 0 ? 12 : 6} hidden={currentPortfolio.size === 0}>
                            <InvestimentSummary current />
                        </Grid>
                        <Grid item xs={currentPortfolio.size === 0 ? 12 : 6} hidden={suggestedPortfolio.size === 0}>
                            <InvestimentSummary />
                        </Grid>
                    </Grid>
                )}


                <InvestmentProjection
                    projection={investPortfolioStorage?.portfolioProjection || investmentPortfolio.portfolioProjection}
                    controlFunction={changeParameters}
                    defaultContribution={additional}
                />
                {loadPortfolioSummary()}
            </div>
        </LoadingPage>
    );
};
export default Investments;

import { Button, Modal, Typography, } from '@material-ui/core';
import React, { useState, useEffect } from 'react';
import { GreyedDateInput, GreyedTextInput } from '../../../global/inputs/GreyedInput';
import { useStyles } from './style';
import MoneyAdornment from '../../../global/adornments/MoneyAdornment';
import CurrencyInput from '../../../global/masks/currencyMaskedInput';
import PercentageInput from '../../../global/masks/percentageMaskedInput';
import unmaskCurrency from '../../../../utils/unmaskCurrency';
import { useDispatch, useSelector } from 'react-redux';
import { IStore } from '../../../../redux/types/store';
import api from '../../../../apis/dreamrichapi';
import { useHistory } from 'react-router';
import { ChangeCustomerFinancing } from '../../../../redux/actions/planningActions';
import { IFinancingRequest } from '../types';

export const ModalEditFinancing = ({ handleCloseEditFinancing, openEditFinancing, financingToEdit, setFinancingRows, financingRows, consortia }) => {

    const classes = useStyles();

    const history = useHistory();

    const dispatch = useDispatch();

    const { userID } = useSelector((store: IStore) => ({ userID: store.planning.customerUserInfo.userID }))

    const [date, setDate] = useState(new Date());
    const [installment, setInstallment] = useState('');
    const [debit, setDebit] = useState('');
    const [interestRate, setInterestRate] = useState('');
    const [name, setName] = useState('');
    const [type, setType] = useState('default');
    const [monthsLeft, setMonthsLeft] = useState(0);

    useEffect(() => {
        if (financingToEdit) {
            setDate(financingToEdit?.financing.date);
            setInstallment(financingToEdit?.financing.installment);
            setDebit(financingToEdit?.financing.debit);
            setInterestRate(financingToEdit?.financing.interestRate);
            setName(financingToEdit?.financing.name);
            setType(financingToEdit?.financing.type);
            setMonthsLeft(financingToEdit?.financing.monthAmount);
        }
    }, [financingToEdit])


    const handleEditFinancing = () => {
        const newFinancing = {
            type,
            name,
            installment: unmaskCurrency(installment),
            interestRate: unmaskCurrency(interestRate),
            debit: unmaskCurrency(debit),
            monthAmount: monthsLeft,
            startDate: date?.toISOString() || new Date().toISOString(),
        }

        financingRows[financingToEdit.index] = newFinancing;

        setFinancingRows(financingRows);

        const financingObj: IFinancingRequest = {
            userID,
            financing: financingRows,
            consortia,
            ready: true
        }

        console.log(financingObj)

        dispatch(ChangeCustomerFinancing(financingObj))


        handleCloseEditFinancing();

        // api.post('/updatefinancing ', newFinancing)
        //     .then(() => {
        //         history.push('/planner');
        //     })
        //     .catch(err => {
        //         if (err.response) {
        //             console.log(`Erro salvando informações de independência financeira: ${err.response.data}`);
        //         } else {
        //             console.log(`Erro interno do sistema: ${err}`);
        //         }
        //     });
    }

    const handleMonthChange = (monthsInput: string) => {
        const parsedVal = parseInt(monthsInput, 10);
        if (!Number.isNaN(parsedVal)) {
            setMonthsLeft(parsedVal);
        } else {
            setMonthsLeft(0);
        }
    };

    return (

        <Modal
            open={openEditFinancing}
            onClose={handleCloseEditFinancing}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
        >
            <div className={classes.paper}>
                <div className='mb-3'>
                    <Typography>Nome: </Typography>
                    <GreyedTextInput
                        color="primary"
                        variant="outlined"
                        size="small"
                        placeholder="Apelido do financiamento"
                        value={name}
                        onChange={event => setName(event.target.value)}
                        fullWidth
                    />
                </div>
                <div className='mb-3 d-flex gap-3'>
                    <div className='d-block'>
                        <Typography>Prestações: </Typography>
                        <GreyedTextInput
                            variant="outlined"
                            size="small"
                            type="number"
                            inputMode="numeric"
                            value={monthsLeft.toString()}
                            onChange={event => handleMonthChange(event.target.value)}
                            InputProps={{
                                inputProps: { min: 0 },
                                endAdornment: <div style={{ padding: '0 8px' }}>meses</div>,
                            }}
                        />
                    </div>
                    <div className='d-block'>
                        <Typography>Juros: </Typography>
                        <GreyedTextInput
                            required
                            color="primary"
                            variant="outlined"
                            size="small"
                            InputProps={{
                                inputComponent: PercentageInput as any,
                                inputProps: { max: 100 },
                                endAdornment: <div style={{ padding: '0 8px' }}>%</div>,
                            }}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            value={interestRate}
                            onChange={event => setInterestRate(event.target.value)}
                        />
                    </div>

                </div>
                <div className='mb-3'>
                    <Typography>Categoria: </Typography>
                    <div className={classes.selectButton}>
                        {/* @ts-ignore */}
                        <Button
                            className={classes.buttonItem}
                            disableElevation
                            variant="outlined"
                            color={type === 'other' ? 'primary' : 'default'}
                            onClick={() => setType('other')}
                        >
                            Outros
                        </Button>
                        {/* @ts-ignore */}
                        <Button
                            className={classes.buttonItem}
                            variant="outlined"
                            color={type === 'price' ? 'primary' : 'default'}
                            disableElevation
                            onClick={() => setType('price')}
                        >
                            Price
                        </Button>
                        {/* @ts-ignore */}
                        <Button
                            className={classes.buttonItem}
                            disableElevation
                            variant="outlined"
                            color={type === 'sac' ? 'primary' : 'default'}
                            onClick={() => setType('sac')}
                        >
                            SAC
                        </Button>
                    </div>
                </div>

                <div className='mb-3'>
                    <Typography>Saldo devedor: </Typography>
                    <GreyedTextInput
                        color="primary"
                        variant="outlined"
                        size="small"
                        placeholder="0.00"
                        InputProps={{ startAdornment: <MoneyAdornment />, inputComponent: CurrencyInput as any }}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        value={debit}
                        onChange={event => setDebit(event.target.value)}
                        fullWidth
                    />
                </div>
                <div className='mb-3'>
                    <Typography>Valor da prestação: </Typography>
                    <GreyedTextInput
                        color="primary"
                        variant="outlined"
                        size="small"
                        placeholder="Coloque o valor da prestação"
                        InputProps={{
                            // inputComponent: CurrencyInput as any,
                            startAdornment: <MoneyAdornment />,
                        }}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        value={installment}
                        onChange={event => setInstallment(event.target.value)}
                        fullWidth
                    />
                </div>
                <div className='mb-3'>
                    <Typography>Data de início: </Typography>
                    <GreyedDateInput
                        fullWidth
                        clearable
                        size="small"
                        format="dd/MM/yyyy"
                        margin="normal"
                        variant="inline"
                        inputVariant="outlined"
                        InputProps={{
                            className: classes.dateInput,
                        }}
                        id="date-picker-inline"
                        value={date}
                        minDate={new Date(21, 9, 2020)}
                        onChange={d => d && setDate(d as Date)}
                        KeyboardButtonProps={{
                            'aria-label': 'change date',
                        }}
                    />
                </div>
                <div className="mb-3">
                    {/* @ts-ignore */}
                    <button className="btn btn-primary" onClick={handleEditFinancing}>Salvar alterações</button>
                </div>
            </div>
        </Modal>
    )
}
import { createStyles, IconButton, makeStyles, Theme, Typography } from '@material-ui/core';
import { ArrowBackIos, ArrowForwardIos } from '@material-ui/icons';
import React from 'react';
import ItemsCarousel from 'react-items-carousel';
import { useDispatch } from 'react-redux';
import api from '../../../../apis/dreamrichapi';
import { ChangeHomeworkMonth, ChangeImplementations } from '../../../../redux/actions/customerActions';
import { UpdateTotalSaved } from '../../../../redux/actions/planningActions';
import InvisibleCard from '../invisibleCard/invisibleCard';
import MonthlyCard from '../monthlyGoalCard/monthlyGoalCard';
import { ICustomerImplementation, IMonthlyGoals, IMonthlyObject } from '../types';
import { useStyles } from './style';

const months = [
    'Janeiro',
    'Fevereiro',
    'Março',
    'Abril',
    'Maio',
    'Junho',
    'Julho',
    'Agosto',
    'Setembro',
    'Outubro',
    'Novembro',
    'Dezembro',
];

interface IMonthlyGoalsProps {
    implementations: IMonthlyObject[];
    MGoals: IMonthlyGoals[];
    year: number;
    yearIndex: number;
    startYear: number;
    endYear: number;
    fetchYear: (year: number) => void;
    userID?: string;
    yearControl: (forward: boolean) => void;
}

const MonthlyGoals: React.FC<IMonthlyGoalsProps> = ({
    MGoals,
    year,
    userID,
    yearIndex,
    implementations,
    yearControl,
}) => {
    const classes = useStyles();
    const [activeItemIndex, setActiveItemIndex] = React.useState(0);

    const [, setLastYear] = React.useState(year);

    React.useEffect(() => {
        const roll = (month: number) => {
            setActiveItemIndex(month);
        };
        const date = new Date();
        const month = date.getMonth();
        roll(month);
    }, []);

    React.useEffect(() => {
        const roll = (month: number) => {
            setActiveItemIndex(month);
        };
        setLastYear(last => {
            if (last === year) return last;
            if (last > year) roll(11);
            else roll(0);
            return year;
        });
    }, [year, setLastYear, setActiveItemIndex]);

    const monthControl = (forward: boolean) => {
        setActiveItemIndex(currActive => {
            if (forward) {
                if (currActive === MGoals.length - 1) {
                    yearControl(true);
                    return 0;
                }
                return ++currActive;
            } else {
                if (currActive === 0) {
                    yearControl(false);
                    return MGoals.length - 1;
                }
                return --currActive;
            }
        });
    };

    const dispatch = useDispatch();

    const [err, setErr] = React.useState('');

    const [savedMoney, setSavedMoney] = React.useState<number[]>([]);
    React.useEffect(() => {
        setSavedMoney(MGoals.map(item => item.saved));
    }, [MGoals, setSavedMoney]);

    const sendGoal = (goal: number, index: number) => {
        if (index > 11) {
            console.debug('Only 11 entries are allowed on months array');
            return;
        }

        const updatedSaved = savedMoney.map((item, i) => (i === index ? goal : item));

        const send = async () => {
            const params = new URLSearchParams();
            params.append('year', year.toString());
            const url = '/customer/savedmoney?';
            api.post<ICustomerImplementation>(url + params.toString(), { savedMoney: updatedSaved, userID }).catch(
                e => {
                    if (e.response) {
                        setErr(e.response.data);
                    }
                }
            );
        };

        setSavedMoney(updatedSaved);
        send();
    };

    const [impl, setImpl] = React.useState<{
        investiments: IMonthlyObject[];
        protections: IMonthlyObject[];
        objectives: IMonthlyObject[];
        consortium: IMonthlyObject[];
    }>({
        protections: [],
        objectives: [],
        investiments: [],
        consortium: [],
    });
    React.useEffect(() => {
        if (implementations) {
            const newImpl = implementations.filter(item => item.id);
            setImpl({
                protections: newImpl.filter(item => item.type === 'insurance'),
                objectives: newImpl.filter(item => item.type === 'pension'),
                investiments: newImpl.filter(item => item.type === 'investiment'),
                consortium: newImpl.filter(item => item.type === 'consortium'),
            });
        }
    }, [yearIndex, MGoals, setImpl, implementations]);

    const [loading, setLoading] = React.useState(false);

    const changeImplementation = async (year: number, implementation: IMonthlyObject) => {
        setLoading(true);
        try {
            implementation.completed = !implementation.completed;
            implementation.completedYear = year;

            const response = await api.post<IMonthlyObject[][]>('/customer/implementation', { implementation, userID });

            dispatch(ChangeImplementations(response.data));

            switch (implementation.type) {
                case 'insurance':
                    setImpl(implement => ({
                        ...implement,
                        protections: implement.protections.map(item =>
                            item.id === implementation.id ? { ...implementation, completed: !item.completed } : item
                        ),
                    }));
                    break;
                case 'pension':
                    setImpl(implement => ({
                        ...implement,
                        objectives: implement.objectives.map(item =>
                            item.id === implementation.id ? { ...item, completed: !item.completed } : item
                        ),
                    }));
                    break;
                case 'investiment':
                    setImpl(implement => ({
                        ...implement,
                        investiments: implement.investiments.map(item =>
                            item.id === implementation.id ? { ...item, completed: !item.completed } : item
                        ),
                    }));
                    break;
                case 'consortium':
                    setImpl(implement => ({
                        ...implement,
                        consortium: implement.consortium.map(item =>
                            item.id === implementation.id ? { ...item, completed: !item.completed } : item
                        ),
                    }));
            }
        } catch (e) {
            console.debug(e);
        }
        setLoading(false);
    };

    React.useEffect(() => {
        dispatch(ChangeHomeworkMonth(activeItemIndex));
        dispatch(UpdateTotalSaved({ index: yearIndex, total: savedMoney.reduce((prev, curr) => prev + curr, 0) }));
    }, [dispatch, savedMoney, yearIndex, activeItemIndex]);

    return (
        <div style={{ margin: '2vh' }}>
            <div className={classes.flex}>
                <Typography className={classes.subHeading} variant="h6">Meta Mensal</Typography>
                <div className={classes.cardItem} />
                <div className={classes.flex}>
                    <Typography>{months[activeItemIndex]} de {year}</Typography>
                    {/* @ts-ignore */}
                    <IconButton onClick={() => monthControl(false)}><ArrowBackIos /></IconButton>
                    {/* @ts-ignore */}
                    <IconButton onClick={() => monthControl(true)}><ArrowForwardIos /></IconButton>
                </div>
            </div>

            <ItemsCarousel
                requestToChangeActive={setActiveItemIndex}
                activeItemIndex={activeItemIndex}
                numberOfCards={3}
                gutter={20} >

                <InvisibleCard />

                {MGoals.map((monthlyGoal, ind) => {

                    return (
                        <>
                            <div key={`mCard-${months[ind]}+${year}`}>
                                <MonthlyCard
                                    changeImplementation={changeImplementation}
                                    implementations={impl}
                                    monthlyGoal={monthlyGoal}
                                    index={ind}
                                    month={months[ind]}
                                    userID={userID}
                                    year={year}
                                    loading={loading}
                                    active={activeItemIndex === ind}
                                    saved={savedMoney[ind] || 0}
                                    send={sendGoal}
                                />
                            </div>
                        </>
                    );
                })}
                <InvisibleCard />
            </ItemsCarousel>
        </div>
    );
};
export default MonthlyGoals;
import { CssBaseline } from '@material-ui/core';
import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Redirect, Route, Switch } from 'react-router';
import api from '../apis/dreamrichapi';
import InvalidPlanning from '../components/customerPages/InvalidPlanning/invalidPlanning';
import Navbar from '../components/global/navigation/navbar/desktop/navbar';
import NavbarMobile from '../components/global/navigation/navbar/mobile/navbarMobile';
import SideBar from '../components/global/navigation/sideBar/desktop/sideBar';
import SideBarMobile from '../components/global/navigation/sideBar/mobile/sideBarMobile';
import { MenuMobile } from '../components/planningForm/planningResult/menuMobile';
import menuOptions from '../menuOptions';
import { IStore } from '../redux/types/store';
import { useStyles, drawerWidth } from './style';
import { useHistory } from 'react-router';

interface IClientPageProps {
    onSignOut: () => void;
}

const CustomerPage: React.FC<IClientPageProps> = ({ onSignOut }) => {
    const classes = useStyles();

    const { planningDate, group } = useSelector((store: IStore) => ({
        planningDate: store.customer.financialHealth.planningDate,
        group: store.loggedUser.currentUser['cognito:groups']?.[0] || '',
    }));

    const realName = useSelector((store: IStore) => store.planning.customerFormValues.customerInfo.personalInfo.personalInfo.fullName);

    const signOut = async () => {
        try {
            await api.get('/signout');
            onSignOut();
        } catch (e) {
            console.debug(e);
        }
    };
    const history = useHistory();

    return (
        <>
            {window.screen.width <= 767 ? (
                <div className={classes.root}>
                    <NavbarMobile planningDate={planningDate} drawerWidth={'100%'} customerName={realName} />
                    <div className={classes.content}>
                        <div className={classes.toolbar} />
                        <CssBaseline />
                        <Switch>
                            {menuOptions.map(({ Component, ...item }, i) =>
                                item.name !== 'Gestão de Riscos' ? (
                                    <Route path={item.url}>
                                        <Component pageNumber={i} />
                                    </Route>
                                ) : null
                            )}
                            <Route path="/invalid_planning" exact>
                                <InvalidPlanning />
                            </Route>
                            <Route path="/menuMobile" exact>
                                <SideBarMobile onSignOut={signOut} drawerWidth={'15%'} customer={true} />
                            </Route>
                            <Redirect exact from="/" to="/menuMobile" />
                        </Switch>
                    </div>
                </div>
            ) : (
                <div className={classes.root}>
                    <Navbar planningDate={planningDate} drawerWidth={drawerWidth} customerName={realName} />
                    <SideBar onSignOut={signOut} drawerWidth={drawerWidth} useRedirect />
                    <div className={classes.content}>
                        <div className={classes.toolbar} />
                        <CssBaseline />
                        <Switch>
                            {menuOptions.map(({ Component, ...item }, i) =>
                                item.name !== 'Gestão de Riscos' && item.name !== 'Carteira & Investimentos' ? (
                                    <Route path={item.url}>
                                        <Component pageNumber={i} />
                                    </Route>
                                ) : null
                            )}
                            <Route path="/invalid_planning" exact>
                                <InvalidPlanning />
                            </Route>
                            <Redirect exact from="/" to="/financialHealth" />

                        </Switch>
                    </div>
                </div>
            )}

        </>
    );
};
export default CustomerPage;

import { Card, CardContent, Typography } from '@material-ui/core';
import React from 'react';
import formatter from '../../../../global/formater';
import { useStyles } from './style'

const Earnings: React.FC<{ monthlyIncome: number; profits: number; bonuses: number }> = ({
    monthlyIncome,
    profits,
    bonuses,
}) => {
    const classes = useStyles();
    const total = monthlyIncome * 12 + profits + bonuses;
    return (
        <>
            {window.screen.width <= 767 ? (
                <>
                    <Typography variant="h6" className={classes.cardHeader} gutterBottom>
                        Receitas
                    </Typography>
                    <div className={classes.cardsLine}>
                        <Card className={classes.cardItemShrink} elevation={2}>
                            <CardContent>
                                <Typography variant="body2">Líquida - R$</Typography>
                                <Typography variant="h5" className={classes.valueText}>
                                    {formatter.format(monthlyIncome)}
                                </Typography>
                                <Typography variant="overline">/MÊS</Typography>
                            </CardContent>
                        </Card>
                        <Card className={classes.cardItemGrow} elevation={2}>
                            <CardContent>
                                <Typography variant="body2">PLR, bonus, dividendos</Typography>
                                <Typography variant="h5" className={classes.valueText}>
                                    {formatter.format(profits)}
                                </Typography>
                                <Typography variant="overline">/ANO</Typography>
                            </CardContent>
                        </Card>
                        <Card className={classes.cardItemShrink} elevation={2}>
                            <CardContent>
                                <Typography variant="body2">13°, Férias</Typography>
                                <Typography variant="h5" className={classes.valueText}>
                                    {formatter.format(bonuses)}
                                </Typography>
                                <Typography variant="overline">/ANO</Typography>
                            </CardContent>
                        </Card>
                    </div>
                    <div className={classes.totalLabel}>
                        <Typography variant="body1" style={{ marginRight: 16 }}>
                            Anual total (líquida)
                        </Typography>
                        <Typography variant="subtitle2" className={classes.totalValue}>
                            {formatter.format(total)}
                        </Typography>
                    </div>
                </>
            ) : (
                <>
                    <Typography variant="h6" className={classes.cardHeader} gutterBottom>
                        Receitas
                    </Typography>
                    <div className={classes.cardsLine}>
                        <Card className={classes.cardItemShrink} elevation={2}>
                            <CardContent>
                                <Typography variant="body2">Líquida - R$</Typography>
                                <Typography variant="h5" className={classes.valueText}>
                                    {formatter.format(monthlyIncome)}
                                </Typography>
                                <Typography variant="overline">/MÊS</Typography>
                            </CardContent>
                        </Card>
                        <Card className={classes.cardItemGrow} elevation={2}>
                            <CardContent>
                                <Typography variant="body2">PLR, bonus, dividendos</Typography>
                                <Typography variant="h5" className={classes.valueText}>
                                    {formatter.format(profits)}
                                </Typography>
                                <Typography variant="overline">/ANO</Typography>
                            </CardContent>
                        </Card>
                        <Card className={classes.cardItemShrink} elevation={2}>
                            <CardContent>
                                <Typography variant="body2">13°, Férias</Typography>
                                <Typography variant="h5" className={classes.valueText}>
                                    {formatter.format(bonuses)}
                                </Typography>
                                <Typography variant="overline">/ANO</Typography>
                            </CardContent>
                        </Card>
                    </div>
                    <div className={classes.totalLabel}>
                        <Typography variant="body1" style={{ marginRight: 16 }}>
                            Anual total (líquida)
                        </Typography>
                        <Typography variant="subtitle2" className={classes.totalValue}>
                            {formatter.format(total)}
                        </Typography>
                    </div>
                </>
            )}

        </>
    );
};

export default Earnings;

import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, Route, Switch, useHistory } from 'react-router';
import menuOptions from '../../../menuOptions';
import { IStore } from '../../../redux/types/store';
import InvalidPlanning from '../../customerPages/InvalidPlanning/invalidPlanning';
import SideBarMobile from '../../global/navigation/sideBar/mobile/sideBarMobile'




export const MenuMobile: React.FC<any> = ({ customer }) => {

    const history = useHistory();
    const pathname = customer ? '' : '/planner/planningResult'


    const { userID } = useSelector((store: IStore) => ({
        userID: store.planning.customerUserInfo.userID,
    }));
    return (
        <div>
            <SideBarMobile onSignOut={() => history.push('/planner')} drawerWidth={'15%'} />
        </div>
    )
}
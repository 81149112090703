import { createStyles, makeStyles, Theme } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            '@media (max-width: 767px)': {
                marginTop: '15%',
            },
            '@media(min-width: 768px)': {
                padding: theme.spacing(0, 4, 4, 4),
                marginTop: '6rem !important',
            },
        },
        loading: {
            height: '100%',
            display: 'flex',
            justifyContent: 'center',
        },
        independenceInfo: {
            '@media (max-width: 767px)':{
                flexWrap: 'wrap',
                alignItems: 'flex-end',
            },
            'media (min-width: 768px)':{
                display: 'flex',
                flexWrap: 'wrap',
                alignItems: 'flex-end',
            },
        },
        childInfo: {
            margin: '8px',
        },
        incomingDisplay: {
            margin: '8px',
            flex: '1 0',
        },
        grow: {
            margin: '8px',
            flex: '1 0 95%',
        },
    })
);

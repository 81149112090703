import { AppBar, ThemeProvider, Toolbar, Typography, useTheme } from '@material-ui/core';
import { useStyles, toolbarTheme } from './style';
import moment from 'moment';
import React from 'react';
import api from '../../../../../apis/dreamrichapi';
import { defaultProfileGain, IFinancialInfo } from '../../../../toolbarInfo/types';
import ValueColorText from '../../../displays/valueColorText/valueColorText';

const Navbar: React.FC<{ drawerWidth: string; planningDate: Date; customerName: string }> = ({
    drawerWidth,
    planningDate,
    customerName
}) => {
    const classes = useStyles({ drawerWidth });
    const theme = useTheme();
    const [financialInfo, setFinancialInfo] = React.useState<IFinancialInfo>({
        cdi: 0,
        ipca: 0,
        profileGain: defaultProfileGain,
        lastRefresh: new Date(),
        realGain: 0,
    });

    const pad = (n: number) => {
        return n < 10 ? '0' + n : n;
    };

    React.useEffect(() => {
        api.get<IFinancialInfo>('/financialinfo')
            .then(response => {
                setFinancialInfo(response.data);
            })
            .catch(e => {
                console.log(e);
            });
    }, []);

    const NewFinancialData = (title: string, value: number, institute: string, addcomma?: boolean) => (
        <div className={classes.line} style={{ marginLeft: theme.spacing(1) }}>
            <Typography className={classes.whiteInfos}>{title.toUpperCase()}</Typography>
            <ValueColorText type="percentage" value={value * 100} textColor={'#AAAAAA'}>
                a.a. {institute && `(${institute.toUpperCase()})`}
                {addcomma && ','}
            </ValueColorText>
        </div>
    );

    const formattedDate = moment(planningDate).format('DD/MM/yyyy');

    return (
        <>
            {window.screen.width <= 767 ? (
                null
            ) : (
                <ThemeProvider theme={toolbarTheme}>
                    <div className={classes.grow}>
                        <AppBar position="fixed" className={classes.appBar} color={'transparent'} elevation={0} >
                            <Toolbar className={classes.toolbar} disableGutters>
                                <div>
                                    <div>
                                        <div className={classes.line} style={{ margin: theme.spacing(1, 0, 0, 2) }}>
                                            <Typography style={{}} className={classes.whiteInfos}>
                                                Dados econômicos:
                                            </Typography>
                                            {NewFinancialData('cdi', financialInfo.cdi, 'CETIP', true)}
                                            {NewFinancialData('inflação', financialInfo.ipca, 'IPCA', true)}
                                            {NewFinancialData('Juros real', financialInfo.realGain, '', false)}
                                        </div>
                                        <div style={{ display: 'flex', flexDirection: 'row' }}>
                                            <div style={{ display: 'flex', margin: theme.spacing(1, 0, 0, 2) }}>
                                                <Typography className={classes.whiteInfos}>Data de início: </Typography>
                                                <Typography style={{ color: '#00878B' }}>{formattedDate}</Typography>
                                            </div>
                                            <div style={{ display: 'flex', margin: theme.spacing(1, 0, 0, 2) }} />
                                            <Typography className={`${classes.clientName} text-capitalize`} color="primary">
                                                {customerName.toLowerCase()}
                                            </Typography>
                                        </div>
                                    </div>
                                </div>
                            </Toolbar>
                        </AppBar>
                    </div>
                </ThemeProvider >
            )}

        </>
    );
};

export default Navbar;

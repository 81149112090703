export type MaritalStatus = 'single' | 'married' | 'divorced' | 'widowed';

export type Gender = 'masculine' | 'feminine' | 'other';
export type DocumentType = 'rg' | 'cnh' | 'passport';

export interface IPersonalData {
    fullName: string;
    dateOfBirth: Date;
    gender: 'masculine' | 'feminine' | 'other';
    maritalStatus: MaritalStatus;
    cpf: string;
    rne?: string;
    jobInfo: IWorkInfo;
    nationality?: string;
    marriageRegime?: string;
    annualGrossIncome?: number;
}

export interface ICustomerInfo {
    personalInfo: IPersonalData;
    partnerInfo: IPersonalData;
    heirs: number;
    heirsName: { name: string; birthdate: Date }[];
    hasPartner: boolean;
}

export interface IAddress {
    cep: string;
    houseNumber: number;
    address: string;
    additionalAddressInfo: string;
    state: string;
    city: string;
    country: string;
}

export interface IContactInfo extends IAddress {
    cellphone: string;
    partnerCellphone: string;
}

export const defaultContactInfo: IContactInfo = {
    cep: '',
    houseNumber: 0,
    address: '',
    additionalAddressInfo: '',
    state: '',
    city: '',
    country: '',
    cellphone: '',
    partnerCellphone: '',
};

export interface IWorkInfo {
    jobTitle: string;
    company: string;
    address: string;
    phoneNumber: string;
}

export const defaultWorkInfo: IWorkInfo = {
    jobTitle: '',
    company: '',
    address: '',
    phoneNumber: '',
};

export const defaultPersonalInfo: ICustomerInfo = {
    partnerInfo: {
        fullName: '',
        dateOfBirth: new Date(),
        gender: 'masculine',
        maritalStatus: 'single',
        cpf: '',
        jobInfo: defaultWorkInfo,
        nationality: 'Brasil',
    },
    personalInfo: {
        fullName: 'Novo Cliente',
        dateOfBirth: new Date(),
        gender: 'masculine',
        maritalStatus: 'single',
        cpf: '',
        jobInfo: defaultWorkInfo,
        nationality: 'Brasil',
    },
    hasPartner: false,
    heirs: 0,
    heirsName: [],
};

export interface ICustomerInfoRequest extends ICustomerInfo, IContactInfo {
    userID: string;
    planningDate: Date;
    email: string;
    plannerUsername: string;
    username: string;
}

const requiredFields = [
    'userID',
    'cellphone',
    'cep',
    'address',
    'state',
    'city',
    'country',
    'fullName',
    'gender',
    'maritalStatus',
    'cpf',
    'documentType',
    'documentValue',
];

export const verifyClientInfo = (info: ICustomerInfo): [boolean, string[]] => {
    let valid = true;
    let missingFields: string[] = [];
    requiredFields.forEach(field => {
        if (!(info as any)[field]) {
            valid = false;
            missingFields = [...missingFields, field];
        }
    });

    return [valid, missingFields];
};

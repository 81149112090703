import { createStyles, makeStyles, Theme } from '@material-ui/core'
import { blueGrey } from '@material-ui/core/colors';

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        rootContent: {
            '@media (max-width: 767px)' :{
                marginTop: '1rem !important',
                padding: '8px'
            },
            '@media (min-width: 768px)' :{
            padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
            },
        },
        rootLined: {
            '@media (max-width: 767px)':{
                marginTop: '1rem !important'
            },
            '@media (min-width: 768px)':{
                display: 'flex',
                justifyContent: 'space-between',
            }
        },
        header: {
            color: blueGrey[900],
        },
        darkPaper: {
            backgroundColor: theme.palette.primary.dark,
            padding: `-${theme.spacing(2)}px`,
        },
        heading: {
            color: blueGrey[900],
        },
        display: {
            '@media (max-width: 767px)' :{

            },
            '@media (min-width: 768px)' :{
                display: 'flex',
                justifyContent: 'space-between',
            },
        },
        line: {
            display: 'flex',
            alignItems: 'flex-start',
        },
        spacedLine: {
            display: 'flex',
            alignItems: 'flex-start',
            justifyContent: 'space-between',
        },
        selector: {
            color: theme.palette.secondary.light,
            fontWeight: 'bold',
        },
        menuItem: {
            '&:selected': {},
            '&:hover': {
                backgroundColor: 'rgba(38, 50, 56, 2.38)',
            },
        },
        divider: {
            margin: `0 ${theme.spacing(1)}px`,
        },
        // for all children
        child: {
            margin: '3px',
        },
        grow: {
            flex: '1 1',
        },
        simulation: {
            maxHeight: 54,
        },
        button: {
            marginLeft: theme.spacing(1),
            alignSelf: 'flex-end',
            backgroundColor: '#023c58',
            color: 'white',
            '&:hover': {
                backgroundColor: '#01579b',
            },
        },
        withdraw: {
            display: 'flex',
            alignSelf: 'flex-end',
            paddingBottom: '14px',
            [theme.breakpoints.down('lg')]: {
                paddingBottom: 3,
            },
        },
    })
);
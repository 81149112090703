import { Button, CircularProgress, LinearProgress, Select } from '@material-ui/core';
import AppBar from '@material-ui/core/AppBar';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { ArrowBack } from '@material-ui/icons';
import AccountCircle from '@material-ui/icons/AccountCircle';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import api from '../../../apis/dreamrichapi';
import { ChangeCurrentStep } from '../../../redux/actions/planningActions';
import { ICustomerUserInfo } from '../../../redux/types/formPlanning';
import { IStore } from '../../../redux/types/store';
import { useStyles } from './style';
import { useLocation } from 'react-router';

interface IMenuBarProps {
    userInfo: ICustomerUserInfo;
    customerName: string;
    menu?: boolean;
    onSignOut: () => void;
    customCustomers: boolean;
}

const MenuAppBar: React.FC<IMenuBarProps> = ({ menu, userInfo, onSignOut, customerName, customCustomers }) => {
    const classes = useStyles();

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);

    const { customerNameRedux } = useSelector((store: IStore) => ({
        customerNameRedux: store.planning.customerFormValues.customerInfo.personalInfo.personalInfo.fullName,
    }));

    const location = useLocation();
    const {pathname} = location;
    const tablePath: boolean = pathname === '/planner/select' ? true : false;

    const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const dispatch = useDispatch();

    const { toolbar } = useSelector((store: IStore) => store.planning);

    const signOut = async () => {
        api.get('/signout')
            .then(() => onSignOut())
            .catch(e => e);
        setAnchorEl(null);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const itemColors = () => {
        switch (toolbar.color) {
            case 'white':
                return 'black';
            case '#F2F3F3':
                return '#023C58';
            default:
                return 'white';
        }
    };

    const colors = itemColors();
    const [loading, setLoading] = React.useState(false);

    const sendInvite = async () => {
        setLoading(true);
        try {
            await api.post('/invite', userInfo);
        } catch (e) {
            console.debug(e);
        }
        setLoading(false);
    };

    const renderFinishButton = () => {
        if (!customCustomers && !tablePath) {
            return (
                //@ts-ignore
                <Button style={{ minWidth: 180, minHeight: 33, marginRight: '5px' }} onClick={sendInvite}>
                    {loading ? <CircularProgress size={15} /> : 'Enviar convite'}
                </Button>
            );
        } else {
            return null;
        }
    };

    const renderLabel = () => {
        if (toolbar.current === 0 && toolbar.total === 0) {
            return (
                <Typography variant="h6" style={{ color: colors }} className={classes.title}>
                    {toolbar.title}
                </Typography>
            );
        }
        return (
            <div className={classes.selectLine}>
                <Typography variant="h6" style={{ color: colors }}>
                    {toolbar.title}
                </Typography>
                <Select
                    className={classes.select}
                    defaultValue={1}
                    color="secondary"
                    style={{ width: 40, margin: '0 16px', backgroundColor: 'white' }}
                    value={toolbar.current}
                    onChange={e => dispatch(ChangeCurrentStep(e.target.value as number))}
                    classes={{
                        root: classes.selectroot,
                    }}
                >
                    {[
                        [...Array(toolbar.total).keys()].map(i => (
                            <MenuItem key={'page-index-select'} value={i + 1}>
                                {' ' + (i + 1)}
                            </MenuItem>
                        )),
                    ]}
                </Select>
                <Typography variant="h6" style={{ color: colors }}>
                    de {toolbar.total}
                </Typography>
            </div>
        );
    };

    return (
        <div className={classes.root}>
            <AppBar elevation={1} className={classes.appBar} position="static">
                <Toolbar style={{ backgroundColor: toolbar.color, maxHeight: 56, minHeight: 0 }}>
                    {(() => {
                        if (window.location.pathname !== '/planner/select') {
                            return (
                                <>
                                    {
                                        !customCustomers &&
                                        //@ts-ignore 
                                        <IconButton
                                            onClick={toolbar.goBack}
                                            edge="start"
                                            className={classes.menuButton}
                                            color="inherit"
                                            aria-label="menu"
                                        >
                                            <ArrowBack style={{ color: colors }} />
                                        </IconButton>
                                    }
                                </>
                            )
                        }
                    })()}
                    {renderLabel()}
                    {renderFinishButton()}
                    {
                        !tablePath &&
                        <Typography style={{ color: 'black', textTransform: 'capitalize' }}>{customerNameRedux.toLowerCase() || customerName.toLowerCase()}</Typography>
                    }
                    <div>
                        {/* @ts-ignore */}
                        <IconButton
                            aria-label="account of current user"
                            aria-controls="menu-appbar"
                            aria-haspopup="true"
                            onClick={handleMenu}
                            color="inherit"
                        >
                            <AccountCircle style={{ color: colors }} />
                        </IconButton>
                        {anchorEl && (
                            <Menu
                                id="menu-appbar"
                                anchorEl={anchorEl}
                                anchorOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                                }}
                                keepMounted
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                                }}
                                open={open}
                                onClose={handleClose}
                            >
                                {/* @ts-ignore */}
                                <MenuItem onClick={signOut}>Sign Out</MenuItem>
                            </Menu>
                        )}
                    </div>
                </Toolbar>
            </AppBar>
            {toolbar.current && toolbar.total ? (
                <LinearProgress variant="determinate" value={(toolbar.current / toolbar.total) * 100} />
            ) : null}
        </div>
    );
};

export default MenuAppBar;

import { Grid, Typography } from '@material-ui/core';
import React from 'react';
import { Area, Bar, CartesianGrid, Cell, ComposedChart, Label, Legend, ResponsiveContainer, Text, Tooltip, XAxis, YAxis, } from 'recharts';
import { sumData } from '../../../../../utils/utils';
import CustomTooltip from '../../../../global/charts/customTooltip/customTooltip';
import renderLegend from '../../../../global/charts/renderLegend';
import formatter from '../../../../global/formater';
import { IIncomingChanges, IPatrimonyProjection, IProjection } from '../../types';
import { useStyles } from '../style';

const sumChangesForIndex = (changes: IIncomingChanges[], index: number) =>
    changes.reduce((prev, curr) => (curr.chartPosition === index ? prev + curr.value : prev), 0);

const convertProjectionToData = (projection: IProjection, currentYear: number, independenceIndex: number) =>
    projection.objectivesProjection
        .map<[number, IPatrimonyProjection]>((item, i) => [item, projection.patrimonyProjection[i]])
        .map(([objectiveCost, patrimonyProjection], i) => {
            let changeData = {};
            if (projection.incomingChanges) {
                const changesSum = sumChangesForIndex(projection.incomingChanges, i);
                if (changesSum > 0) {
                    changeData = { aumento: changesSum };
                } else if (changesSum < 0) {
                    changeData = { redução: changesSum };
                }
            }
            return {
                name: `${currentYear + i}`,
                objetivo: i <= independenceIndex ? objectiveCost : 0,
                juros: patrimonyProjection.interest,
                patrimônio: patrimonyProjection.investmentAsset + patrimonyProjection.fixedAsset,
                independência: i > independenceIndex ? objectiveCost : undefined,
                previdência: i <= independenceIndex ? patrimonyProjection.pension : undefined,
                ...changeData,
            };
        })
        .map((data, i) => (i > independenceIndex + 1 ? { ...data, objetivo: undefined } : data));

export interface IComparisonProps {
    projection: IProjection;
    achievedIncoming: number;
    independenceYear: number;
    neededForIndependence: number;
    patrimonyAtIndependence: number;
    interestAcc: number;
}

const barSize = 12;

const ComparisonMobile: React.FC<IComparisonProps> = ({
    achievedIncoming,
    neededForIndependence,
    projection,
    independenceYear,
    patrimonyAtIndependence,
    interestAcc,
}) => {
    const classes = useStyles();

    const currentYear = new Date().getFullYear();
    const independenceIndex = independenceYear - currentYear;

    const projectionNotValues: IProjection = {
        incomingChanges: [],
        objectivesProjection: [],
        patrimonyProjection: []
    }

    const [data, setData] = React.useState(convertProjectionToData(projection || projectionNotValues, currentYear, independenceIndex || currentYear));
    React.useEffect(() => {
        const data = convertProjectionToData(projection, currentYear, independenceIndex).filter((item, index) =>
            index > independenceIndex ? sumData(item) > 0 : item
        );
        setData(data);
    }, [projection, currentYear, independenceIndex]);

    const patrimonyLag = patrimonyAtIndependence - neededForIndependence;

    const ref = React.createRef<null | any>();
    const [position, setPosition] = React.useState(0);

    React.useEffect(() => {
        if (ref.current) {
            setPosition(ref.current.container.offsetWidth);
        }
    }, [ref, setPosition]);

    return (
        <div className={classes.group}>
            <div className={classes.innerPadding}>
                <Typography className={classes.subHeading} variant="h6">Projeção</Typography>
                <ResponsiveContainer ref={ref} width={400} height={280}>
                    <ComposedChart margin={{ right: 40, left: 40 }} data={data}>
                        <defs>
                            <linearGradient id="colorUv" x1="0%" y1="100%" x2="0%" y2="0%">
                                <stop offset="0%" stopColor="#26999c" />
                                <stop offset="63%" stopColor="#065875" />
                                <stop offset="100%" stopColor="#023c58" />
                            </linearGradient>
                        </defs>
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="name" padding={{ left: 12, right: 12 }} />
                        <Bar barSize={barSize} stackId="a" yAxisId="left" dataKey="patrimônio" fill="url(#colorUv)">
                            {data.map((val, index) => {
                                let fill = 'url(#colorUv)';
                                if (index === independenceIndex || sumData(val) < 0) {
                                    fill = '#29B6F6';
                                }
                                return <Cell key={'flow-cell-' + index} fill={fill} />;
                            })}
                        </Bar>
                        <Tooltip content={<CustomTooltip />} />
                    </ComposedChart>
                </ResponsiveContainer>

                <div className='d-column mt-3'>
                    <div className={classes.summary}>
                        <Typography className={classes.summaryHeader}>Patrimônio final na independência financeira</Typography>
                        <Typography className={classes.summaryValue} color="primary">{formatter.format(patrimonyAtIndependence)}</Typography>
                    </div>
                    <div className={classes.summary}>
                        <Typography className={classes.summaryHeader}>{' '}Renda alcançada (mensal, 3% a.a.)</Typography>
                        <Typography className={classes.summaryValue} color="primary">{formatter.format(achievedIncoming)}</Typography>
                    </div>
                    <div className={classes.summary}>
                        <Typography className={classes.summaryHeader}>Juros acumulado</Typography>
                        <Typography className={classes.summaryValue} color="primary">{formatter.format(interestAcc)}</Typography>
                    </div>
                    <div className={classes.summary}>
                        <Typography className={classes.summaryHeader}>Defasagem do patrimônio</Typography>
                        <Typography
                            className={classes.summaryValue}
                            style={{ color: patrimonyLag > 0 ? '#a0d633' : '#e65100' }}
                        >
                            {formatter.format(patrimonyLag)}
                        </Typography>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ComparisonMobile;

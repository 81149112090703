import React from 'react';
import './styles.scss';

// https://github.com/andreruffert/scratchie
// https://codepen.io/Totati/pen/pPXrJV

class ScratchOff extends React.PureComponent<{ saveShow: () => any; show: boolean; hide: () => any }> {
    static defaultProps = {
        // brush: null,
        // cover: null,
        // threshold
    };
    isDrawing: boolean;
    lastPoint: {
        x: number;
        y: number;
    };
    canvas: any;
    ctx: any;
    brush: HTMLImageElement;
    cover: HTMLImageElement;
    show: boolean;
    saveShow: () => void;
    hide: () => any;
    enableClick: boolean;

    constructor(props: Readonly<{ saveShow: () => any; show: boolean; hide: () => any }>) {
        super(props);
        this.saveShow = props.saveShow;
        this.show = props.show;

        this.hide = props.hide;
        this.isDrawing = false;
        this.lastPoint = { x: 0, y: 0 };
        this.brush = new Image();
        this.cover = new Image();
        this.touchStart = this.touchStart.bind(this);
        this.touchMove = this.touchMove.bind(this);
        this.touchEnd = this.touchEnd.bind(this);
        this.enableClick = props.show;
    }

    componentDidMount() {
        const canvas = this.canvas;
        canvas.width = canvas.parentElement.offsetWidth;
        canvas.height = canvas.parentElement.offsetHeight;

        canvas.addEventListener('mousedown', this.touchStart);
        canvas.addEventListener('touchstart', this.touchStart);
        canvas.addEventListener('mousemove', this.touchMove);
        canvas.addEventListener('touchmove', this.touchMove);
        canvas.addEventListener('mouseup', this.touchEnd);
        canvas.addEventListener('touchend', this.touchEnd);

        this.ctx = canvas.getContext('2d');

        this.brush = new Image();
        this.brush.src = '/brush.png';

        this.cover = new Image();
        this.cover.src = '/logo-cake.svg';
        this.cover.onload = () => {
            this.ctx.drawImage(this.cover, 0, 0, canvas.width, canvas.height);
        };
    }

    componentWillUnmount() {
        const canvas = this.canvas;
        canvas.removeEventListener('mousedown', this.touchStart);
        canvas.removeEventListener('touchstart', this.touchStart);
        canvas.removeEventListener('mousemove', this.touchMove);
        canvas.removeEventListener('touchmove', this.touchMove);
        canvas.removeEventListener('mouseup', this.touchEnd);
        canvas.removeEventListener('touchend', this.touchEnd);
    }

    getPosition(event: { pageX: any; touches: { clientY: any; clientX: any }[]; pageY: any }) {
        let target = this.canvas;
        let offsetX = 0;
        let offsetY = 0;

        if (target.offsetParent !== undefined) {
            while ((target = target.offsetParent)) {
                offsetX += target.offsetLeft;
                offsetY += target.offsetTop;
            }
        }

        const x = (event.pageX || event.touches[0].clientX) - offsetX;
        const y = (event.pageY || event.touches[0].clientY) - offsetY;
        return { x, y };
    }

    touchStart(event: { pageX: any; touches: { clientY: any; clientX: any }[]; pageY: any }) {
        this.isDrawing = true;
        this.lastPoint = this.getPosition(event);
        this.ctx.globalCompositeOperation = 'destination-out';
    }

    touchMove(event: { preventDefault: any; pageX: any; touches: { clientY: any; clientX: any }[]; pageY: any }) {
        if (!this.isDrawing) return;
        this.enableClick = false;

        event.preventDefault();

        const ctx = this.ctx;
        const a = this.lastPoint;
        const b = this.getPosition(event);
        const dist = Math.sqrt(Math.pow(b.x - a.x || 0, 2) + Math.pow(b.y - a.y || 0, 2));
        const angle = Math.atan2(b.x - a.x, b.y - a.y);
        const offsetX = this.brush.width / 2;
        const offsetY = this.brush.height / 2;

        for (let x, y, i = 0; i < dist; i++) {
            x = a.x + Math.sin(angle) * i - offsetX;
            y = a.y + Math.cos(angle) * i - offsetY;
            ctx.drawImage(this.brush, x, y);
        }

        this.lastPoint = b;
    }

    touchEnd() {
        this.saveShow();

        this.isDrawing = false;
        setTimeout(() => {
            this.enableClick = true;
        }, 5);
    }

    reset() {
        if (this.enableClick) {
            this.show = false;
            const canvas = this.canvas;
            canvas.width = canvas.parentElement.offsetWidth;
            canvas.height = canvas.parentElement.offsetHeight;

            canvas.addEventListener('mousedown', this.touchStart);
            canvas.addEventListener('touchstart', this.touchStart);
            canvas.addEventListener('mousemove', this.touchMove);
            canvas.addEventListener('touchmove', this.touchMove);
            canvas.addEventListener('mouseup', this.touchEnd);
            canvas.addEventListener('touchend', this.touchEnd);

            this.ctx = canvas.getContext('2d');

            this.brush = new Image();
            this.brush.src = '/brush.png';

            this.cover = new Image();
            this.cover.src = '/logo-cake.svg';
            this.cover.onload = () => this.ctx.drawImage(this.cover, 0, 0, canvas.width, canvas.height);
            this.hide();
        }
    }

    render() {
        return (
            // @ts-ignore
            <div onClick={() => this.reset()} style={{ position: 'relative', width: 220, height: 50 }}>
                <canvas
                    style={{
                        position: 'absolute',
                        zIndex: 2,
                        width: '102%',
                        height: '100%',
                    }}
                    ref={el => (this.canvas = el)}
                    hidden={this.show}
                />
                <div className="secret absolute fill no-select flex justify-center items-center">
                    {this.props.children}
                </div>
            </div>
        );
    }
}

export default ScratchOff;

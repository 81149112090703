import { createStyles, makeStyles, Theme } from '@material-ui/core';
import { lightBlue } from '@material-ui/core/colors';

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            '@media (max-width: 767px)':{
                
            },
            '@media (min-width: 768px)':{
                maxWidth: '97%',
                padding: theme.spacing(0, 4, 4, 4),
            },
        },
        header: {
            '@media (max-width: 767px)': {
                justifyContent: 'flex-start',
                alignItems: 'center',
                marginTop: '6rem !important',
                marginLeft: '8px',
                marginBottom: '16px',
            },
            '@media (min-width: 768px)': {
                display: 'flex',
                justifyContent: 'flex-start',
                alignItems: 'center',
                marginLeft: '8px',
                marginBottom: '16px',
            }
        },
        heading: {
            color: '#2C2F31',
            paddingLeft: '5px',
            marginBottom: theme.spacing(1),
        },
        grow: {
            flexGrow: 1,
        },
        loading: {
            padding: theme.spacing(2),
        },
        textSize: {
            fontSize: '0.875rem',
        },
        input: {
            padding: '10px 14px',
        },
        tableContainer: {
            marginTop: theme.spacing(2),
        },
        last: {
            backgroundColor: lightBlue[50],
        },
        monthSelector: {
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
        },
    })
);
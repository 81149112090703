import { createStyles, makeStyles, Theme } from '@material-ui/core';
import { grey } from '@material-ui/core/colors';

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        content: {
            display: 'flex',
            marginTop: theme.spacing(1),
            padding: theme.spacing(2),
        },
        contentMobile: {
            marginTop: theme.spacing(1),
            padding: theme.spacing(2),
        },
        free: {
            '@media (max-width: 767px)':{
                backgroundColor: theme.palette.primary.dark,
                flexBasis: '30%',
            },
            '@media (max-width: 768px)':{
                backgroundColor: theme.palette.primary.dark,
                flexBasis: '30%',
                margin: `-${theme.spacing(2)}px`,
            }
        },
        freeLabel: {
            color: theme.palette.success.main,
        },
        whiteLabel: {
            color: 'white',
            flexGrow: 1,
        },
        freeContent: {
            '@media (max-width: 767px)': {
                marginTop: '1.5rem !important',
                display: 'flex',
                height: '100%',
                flexDirection: 'column',
                alignItems: 'center',
                alignContent: 'center',
            },
            '@media (min-width: 768px)':{
                display: 'flex',
                height: '100%',
                flexDirection: 'column',
                alignItems: 'center',
                alignContent: 'center',
            }
        },
        divider: {
            borderLeft: `1px solid ${grey[400]}`,
            margin: `6px ${theme.spacing(2)}px`,
        },
        grow: {
            flexGrow: 1,
        },
    })
);
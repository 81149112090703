import { createStyles, makeStyles, Theme } from '@material-ui/core';
export const useStyles = makeStyles((theme: Theme) =>
    createStyles({

        root: {
            '@media (max-width: 767px)':{
                width: '98%',
                padding: theme.spacing(0, 4, 4, 4),
                marginTop: '6rem !important',
            },
            '@media (min-width: 768px)':{
                width: '98%',
                padding: theme.spacing(0, 4, 4, 4),
                marginTop: '2rem !important',
            }
        },
        inputLabel: {
            marginBottom: '10px',
        },
        subHeading: {
            color: '#2C2F31',
            fontWeight: 600,
            marginBottom: '12px',
        },
        cardLine: {
            '@media (min-width: 768px)':{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                width: '70%'
            }
            
        },
        cardItem: {
            flexGrow: 1,
        },
        spacing: {
            padding: theme.spacing(1),
        },
        lastCard: {
            maxWidth: '30vw',
        },
        errorTextColor: {
            color: theme.palette.error.main,
        },
        secondaryTextColor: {
            color: theme.palette.secondary.main,
        },
        orlabel: {
            margin: theme.spacing(0, 2),
            fontSize: 16,
        },
        // SuggestionCard
        cardHeader: {
            fontWeight: 600,
            fontSize: 14,
            color: 'white',
            maxWidth: 728,
        },
        content: {
            display: 'flex',
            flexDirection: 'row',
            alignContent: 'flex-start',
            alignItems: 'flex-end',
            '& > :not(:first-child)': {
                marginLeft: theme.spacing(0),
            },
        },
        cardValuePositive: {
            color: theme.palette.success.main,
        },
        cardValueNegative: {
            color: theme.palette.error.main,
        },
        w50: {
            width: '50%'
        },
        w80: {
            width: '80%',
        },
    })
);
import { Equalizer } from '@material-ui/icons';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import api from '../../../apis/dreamrichapi';
import { ChangeFormValues } from '../../../redux/actions/planningActions';
import { ICustomerFetch, ICustomerFormValues } from '../../../redux/types/formPlanning';
import { IStore } from '../../../redux/types/store';
import { defaultContactInfo, defaultPersonalInfo } from '../customerInfo/types';
import { IFinancingValue } from '../financing/types';
import StepCard from '../stepCard/stepCard';
import { reduceCustomerInfo } from '../utils/utils';
import { useStyles } from './style'

const convertFetchToRedux = (fetch: ICustomerFetch): ICustomerFormValues => {
    let financing: IFinancingValue[] = [];
    try {
        if (fetch.financing) {
            if (fetch.financing.financing.price) {
                financing = [
                    ...financing,
                    ...fetch.financing.financing.price.map<IFinancingValue>(item => ({ ...item, type: 'price' })),
                ];
            }
            if (fetch.financing.financing.sac) {
                financing = [
                    ...financing,
                    ...fetch.financing.financing?.sac?.map<IFinancingValue>(item => ({ ...item, type: 'sac' })),
                ];
            }
            if (fetch.financing.financing.other) {
                financing = [
                    ...financing,
                    ...fetch.financing.financing?.other?.map<IFinancingValue>(item => ({ ...item, type: 'other' })),
                ];
            }

            localStorage.setItem('storageCustomer' + fetch.incoming.userID, JSON.stringify(fetch));
            localStorage.setItem('userID', JSON.stringify(fetch.incoming.userID));
        }
    } catch (e) {
        console.debug(e);
    }

    return {
        ...fetch,
        customerInfo: {
            contactInfo: { ...reduceCustomerInfo(defaultContactInfo, fetch.customerInfo) },
            personalInfo: {
                ...reduceCustomerInfo(defaultPersonalInfo, fetch.customerInfo),
                hasPartner: fetch.customerInfo.personalInfo.maritalStatus === 'married',
            },
        },
        financing: {
            ...fetch.financing,
            financing,
        },
    };
};

const PlanningSteps: React.FC<any> = ({ customcustomers, userStorage }) => {

    const { formState } = useSelector((store: IStore) => ({ formState: store.planning }));
    const classes = useStyles();

    const [loading, setLoading] = useState(false);
    const history = useHistory();

    const dispatch = useDispatch();

    useEffect(() => {
        if (!formState.fetch) {
            setLoading(true);
        }
        if (!formState.customerUserInfo.userID) {
            history.push('/');
        }

        if (formState.customerUserInfo?.userID || userStorage?.userID) {
            api.get<ICustomerFetch>('getcustomer', {
                params: { customer: userStorage?.userID || formState.customerUserInfo.userID },
            })
                .then(res => {
                    if (res.data) {
                        const converted = convertFetchToRedux(res.data);
                        const customerBirth = (converted.customerInfo.personalInfo.personalInfo
                            .dateOfBirth as unknown) as string;
                        converted.customerInfo.personalInfo.personalInfo.dateOfBirth = customerBirth
                            ? new Date(customerBirth)
                            : new Date();
                        const partnerBirth = (converted.customerInfo.personalInfo.partnerInfo
                            .dateOfBirth as unknown) as string;
                        converted.customerInfo.personalInfo.partnerInfo.dateOfBirth = partnerBirth
                            ? new Date(partnerBirth)
                            : new Date();

                        dispatch(ChangeFormValues(converted));
                    }
                    setLoading(false);
                })
                .catch(e => {
                    console.error(e);
                    setLoading(false);
                });
        }
    }, [formState.customerUserInfo.userID, formState.fetch]);



    const canLoadPlanning = () => {
        const birth = moment(
            formState.customerFormValues.customerInfo.personalInfo.personalInfo.dateOfBirth,
            'DD/MM/YYYY'
        ).toDate();
        if (isNaN(birth.getFullYear())) {
            return false;
        }
        return true;
    };

    return (
        <>
            {window.screen.width <= 767 ? (
                <div className="ps-2 pe-2 ps-md-0 pe-md-0 d-flex flex-column justify-content-center align-items-center pb-5">
                    <div className="mb-4" style={{ height: '0 !important' }}>
                        <div className="col-md-12 mt-4">
                            <h4 className='text-center'>Bem vindo</h4>
                            <h6 className='text-center'>Você precisará preencher cada uma das áreas abaixo para concluir o projeto de vida.</h6>
                        </div>
                    </div>
                    <div className="row w-100 d-flex">
                        <div className="col-md-4 d-flex flex-column gap-4 ps-4 pe-2">
                            <StepCard
                                loading={loading}
                                className={classes.bottomPadding}
                                cardType="usuário"
                                done={formState.userInfoStep}
                                title="Dados do cliente"
                                redirectPath="/planner/clientinfo"
                                negative
                                backgroundImage='client'
                            >
                                <span style={{ fontSize: '14px' }} className="mb-3 ">
                                    Cadastre um novo cliente (nome, email e senha)
                                    <br />
                                </span>
                            </StepCard>

                            <StepCard
                                loading={loading}
                                className={classes.bottomPadding}
                                cardType="despesas"
                                done={formState.fixedCostStep}
                                title="Custo Fixo"
                                redirectPath="/planner/fixedcost"
                                negative
                                backgroundImage='fixedCost'
                            >
                                <span style={{ fontSize: '14px' }} className="mb-3">
                                    Quanto da renda é consumida mensalmente
                                    <br />
                                </span>
                            </StepCard>

                            <StepCard
                                loading={loading}
                                className={classes.bottomPadding}
                                cardType="segurança"
                                done={formState.riskmanagementStep}
                                title="Gestão de riscos"
                                redirectPath="/planner/risk"
                                negative
                                backgroundImage='risk'
                            >
                                <span style={{ fontSize: '14px' }} className="mb-3">
                                    Seguros de vida, patrimonial, etc.
                                    <br />
                                </span>
                            </StepCard>
                        </div>

                        <div className="col-md-4 d-flex flex-column gap-4 ps-2 pe-2">
                            <StepCard
                                loading={loading}
                                className={classes.bottomPadding}
                                cardType="sonhos"
                                done={formState.goalStep}
                                title="Objetivos"
                                redirectPath="/planner/goals"
                                negative
                                backgroundImage='goals'
                            >
                                <span style={{ fontSize: '14px' }} className="mb-3">
                                    Quais os objetivos do cliente
                                    <br />
                                </span>
                            </StepCard>

                            <StepCard
                                loading={loading}
                                className={classes.bottomPadding}
                                cardType="receitas"
                                done={formState.incomingStep}
                                title="Renda"
                                redirectPath="/planner/incoming"
                                negative
                                backgroundImage='incoming'
                            >
                                <span style={{ fontSize: '14px' }} className="mb-3">
                                    Renda líquida total aferida
                                    <br />
                                </span>
                            </StepCard>

                            {
                                !customcustomers ?
                                    <StepCard
                                        loading={loading}
                                        className={classes.bottomPadding}
                                        cardType="sugestões"
                                        done={formState.suggestionsStep}
                                        title="Sugestões"
                                        redirectPath="/planner/suggestions"
                                        backgroundImage='suggestions'
                                    >
                                        <span style={{ fontSize: '14px' }} className="mb-3">
                                            Sugestões para o cliente
                                            <br />
                                        </span>

                                    </StepCard>
                                    :
                                    <StepCard
                                        loading={loading}
                                        disabled={!canLoadPlanning()}
                                        className={classes.bottomPadding}
                                        cardType="usuário"
                                        CustomIcon={Equalizer}
                                        done={false}
                                        title="Verificar projeto de vida"
                                        redirectPath="/planner/menuMobile/"
                                        backgroundImage='project'
                                    >
                                        <span>
                                            -
                                            <br />
                                        </span>
                                    </StepCard>
                            }

                        </div>

                        <div className="col-md-4 d-flex flex-column gap-4 ps-2 pe-4">
                            <StepCard
                                loading={loading}
                                className={classes.bottomPadding}
                                cardType="passivos"
                                done={formState.financingStep}
                                title="Financiamentos"
                                redirectPath="/planner/financing"
                                negative
                                backgroundImage='financing'
                            >
                                <span style={{ fontSize: '14px' }} className="mb-3">
                                    Financiamentos em andamento
                                    <br />
                                </span>
                            </StepCard>

                            <StepCard
                                loading={loading}
                                className={classes.bottomPadding}
                                cardType="ativos"
                                done={formState.patrimonyStep}
                                title="Patrimônio"
                                redirectPath="/planner/patrimony"
                                negative
                                backgroundImage='patrimony'
                            >
                                <span style={{ fontSize: '14px' }} className="mb-3">
                                    Quanto de patrimônio o cliente tem acumulado
                                    <br />
                                </span>
                            </StepCard>

                            {
                                !customcustomers ?
                                    <StepCard
                                        loading={loading}
                                        disabled={!canLoadPlanning()}
                                        className={classes.bottomPadding}
                                        cardType="usuário"
                                        CustomIcon={Equalizer}
                                        done={false}
                                        title="Verificar projeto de vida"
                                        redirectPath="/planner/menuMobile/"
                                        backgroundImage='project'
                                    >
                                        <span>
                                            -
                                            <br />
                                        </span>
                                    </StepCard>
                                    :
                                    null
                            }
                        </div>
                    </div >
                </div >
            ) : (
                <div className="ps-2 pe-2 ps-md-0 pe-md-0 d-flex flex-column justify-content-center align-items-center pb-5">
                    <div className="mb-4" style={{ height: '0 !important' }}>
                        <div className="col-md-12 mt-4">
                            <h4 className='text-center'>Bem vindo</h4>
                            <h6 className='text-center'>Você precisará preencher cada uma das áreas abaixo para concluir o projeto de vida.</h6>
                        </div>
                    </div>
                    <div className="row w-100 d-flex">
                        <div className="col-md-4 d-flex flex-column gap-4 ps-4 pe-2">
                            <StepCard
                                loading={loading}
                                className={classes.bottomPadding}
                                cardType="usuário"
                                done={formState.userInfoStep}
                                title="Dados do cliente"
                                redirectPath="/planner/clientinfo"
                                negative
                                backgroundImage='client'
                            >
                                <span style={{ fontSize: '14px' }} className="mb-3 ">
                                    Cadastre um novo cliente (nome, email e senha)
                                    <br />
                                </span>
                            </StepCard>

                            <StepCard
                                loading={loading}
                                className={classes.bottomPadding}
                                cardType="despesas"
                                done={formState.fixedCostStep}
                                title="Custo Fixo"
                                redirectPath="/planner/fixedcost"
                                negative
                                backgroundImage='fixedCost'
                            >
                                <span style={{ fontSize: '14px' }} className="mb-3">
                                    Quanto da renda é consumida mensalmente
                                    <br />
                                </span>
                            </StepCard>

                            <StepCard
                                loading={loading}
                                className={classes.bottomPadding}
                                cardType="segurança"
                                done={formState.riskmanagementStep}
                                title="Gestão de riscos"
                                redirectPath="/planner/risk"
                                negative
                                backgroundImage='risk'
                            >
                                <span style={{ fontSize: '14px' }} className="mb-3">
                                    Seguros de vida, patrimonial, etc.
                                    <br />
                                </span>
                            </StepCard>
                        </div>

                        <div className="col-md-4 d-flex flex-column gap-4 ps-2 pe-2">
                            <StepCard
                                loading={loading}
                                className={classes.bottomPadding}
                                cardType="sonhos"
                                done={formState.goalStep}
                                title="Objetivos"
                                redirectPath="/planner/goals"
                                negative
                                backgroundImage='goals'
                            >
                                <span style={{ fontSize: '14px' }} className="mb-3">
                                    Quais os objetivos do cliente
                                    <br />
                                </span>
                            </StepCard>

                            <StepCard
                                loading={loading}
                                className={classes.bottomPadding}
                                cardType="receitas"
                                done={formState.incomingStep}
                                title="Renda"
                                redirectPath="/planner/incoming"
                                negative
                                backgroundImage='incoming'
                            >
                                <span style={{ fontSize: '14px' }} className="mb-3">
                                    Renda líquida total aferida
                                    <br />
                                </span>
                            </StepCard>

                            {
                                !customcustomers ?
                                    <StepCard
                                        loading={loading}
                                        className={classes.bottomPadding}
                                        cardType="sugestões"
                                        done={formState.suggestionsStep}
                                        title="Sugestões"
                                        redirectPath="/planner/suggestions"
                                        backgroundImage='suggestions'
                                    >
                                        <span style={{ fontSize: '14px' }} className="mb-3">
                                            Sugestões para o cliente
                                            <br />
                                        </span>

                                    </StepCard>
                                    :
                                    <StepCard
                                        loading={loading}
                                        disabled={!canLoadPlanning()}
                                        className={classes.bottomPadding}
                                        cardType="usuário"
                                        CustomIcon={Equalizer}
                                        done={false}
                                        title="Verificar projeto de vida"
                                        redirectPath="/planner/planningresult/"
                                        backgroundImage='project'
                                    >
                                        <span>
                                            -
                                            <br />
                                        </span>
                                    </StepCard>
                            }

                        </div>

                        <div className="col-md-4 d-flex flex-column gap-4 ps-2 pe-4">
                            <StepCard
                                loading={loading}
                                className={classes.bottomPadding}
                                cardType="passivos"
                                done={formState.financingStep}
                                title="Financiamentos"
                                redirectPath="/planner/financing"
                                negative
                                backgroundImage='financing'
                            >
                                <span style={{ fontSize: '14px' }} className="mb-3">
                                    Financiamentos em andamento
                                    <br />
                                </span>
                            </StepCard>

                            <StepCard
                                loading={loading}
                                className={classes.bottomPadding}
                                cardType="ativos"
                                done={formState.patrimonyStep}
                                title="Patrimônio"
                                redirectPath="/planner/patrimony"
                                negative
                                backgroundImage='patrimony'
                            >
                                <span style={{ fontSize: '14px' }} className="mb-3">
                                    Quanto de patrimônio o cliente tem acumulado
                                    <br />
                                </span>
                            </StepCard>

                            {
                                !customcustomers ?
                                    <StepCard
                                        loading={loading}
                                        disabled={!canLoadPlanning()}
                                        className={classes.bottomPadding}
                                        cardType="usuário"
                                        CustomIcon={Equalizer}
                                        done={false}
                                        title="Verificar projeto de vida"
                                        redirectPath="/planner/planningresult/"
                                        backgroundImage='project'
                                    >
                                        <span>
                                            -
                                            <br />
                                        </span>
                                    </StepCard>
                                    :
                                    null
                            }
                        </div>
                    </div >
                </div >
            )}

        </>
    );
};

export default PlanningSteps;
import { ThemeProvider } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router';
import api, { setupApi } from './apis/dreamrichapi';
import './App.scss';
import LoadingPage from './components/global/loadingPage/desktop/loadingPage';
import Login from './components/login/login';
import { IUserInfo } from './components/login/types';
// import CustomerPage from './CustomerPage/CustomerPage';
import PlannerPage from './PlannerPage/PlannerPage';
import { ConfigPage } from './ConfigPage/ConfigPage';
import { UpdateCurrentLogged } from './redux/actions/userActions';
import theme from './theme/theme';
import CustomerPage from './CustomerPage/CustomerPage';
import { TermPage } from './TermPage/TermPage';


const App: React.FC = () => {
    const [profileType, setProfileType] = useState('');
    const [loginDone, setLoginDone] = useState(false);
    const [signOut, setSignOut] = useState(false);
    const [aux, setAux] = useState(0);

    const path = useLocation().pathname;

    const history = useHistory();
    const dispatch = useDispatch();

    const onSignOut = () => {
        setAux(0);
        setSignOut(true);
    };

    useEffect(() => {
        if (path.indexOf('/termo') !== -1) {
            setPage(loadTermPage())
        } else {
            setPage(loadAppPage())
        }
        setupApi(history);
    }, []);

    const loadTermPage = (): JSX.Element => { return <TermPage /> }
    const loadAppPage = (): JSX.Element => { return <App /> }

    useEffect(() => {

        if (path.indexOf('/termo') === -1) {
            if (signOut) {
                setSignOut(false);
                setLoginDone(false);
            }
            // find user page and set profile type based on server response
            const callApi = async () => {
                try {
                    const response = await api.get<IUserInfo>('/ping');

                    const profile = response.data['cognito:groups']?.[0]?.toLowerCase() || 'login';

                    if (profile !== 'login') {
                        setLoginDone(true);
                    }
                    setAux(0);
                    setProfileType(profile);
                    dispatch(UpdateCurrentLogged(response.data));
                } catch (err) {
                    setProfileType('login');
                }
            };

            const getPageForProfile = (profile: string): JSX.Element => {
                switch (profile) {
                    case 'login':
                        return (
                            <Login
                                finishLogin={() => {
                                    setAux(0);
                                    setLoginDone(true);
                                }}
                            />
                        );
                    case 'planners':
                    case 'admin':
                        return <PlannerPage onSignOut={onSignOut} profileTitle={profile} customcustomers={false}/>;
                    case 'customcustomers':
                        return <PlannerPage onSignOut={onSignOut} profileTitle={profile} customcustomers={true} />;
                    case 'customers':
                        return <CustomerPage onSignOut={onSignOut} />;
                    case 'walkr':
                        return <ConfigPage />;
                    default:
                        return <LoadingPage loading={true} />;
                }
            };
            setPage(getPageForProfile(profileType));

            if (aux === 0) {
                setAux(1);
                callApi();
            }
        }
    }, [loginDone, profileType, signOut]);

    const [page, setPage] = useState<JSX.Element>(<LoadingPage loading={true} />);

    return (
        <ThemeProvider theme={theme}>
            <div className="App">{page}</div>
        </ThemeProvider>
    );
};

export default App;

import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import api from '../../../../apis/dreamrichapi';
import { ChangeFinancialHealth } from '../../../../redux/actions/customerActions';
import { SelectCurrentPage } from '../../../../redux/actions/planningActions';
import { IStore } from '../../../../redux/types/store';
import LoadingPage from '../../../global/loadingPage/desktop/loadingPage';
import LoadingPageMobile from '../../../global/loadingPage/mobile/loadingPageMobile'
import { IFinancialInfo } from '../../../toolbarInfo/types';
import { ICustomerPageProps } from '../../types';
import CurrentState from '../currentState/desktop/currentState';
import CurrentStateMobile from '../currentState/mobile/currentStateMobile'
import DesiredState from '../desiredState/desktop/desiredState';
import DesiredStateMobile from '../desiredState/mobile/desiredStateMobile';
import { IFinancialHealth } from '../types';
import YourNumber from '../yourNumber/desktop/yourNumber';
import YourNumberMobile from '../yourNumber/mobile/yourNumberMobile'
import { useStyles } from './style';
import FinancialIndependenceGoalsMobile from '../financialIndependence/mobile/financialIndependenceGoalMobile'
import Suggestions from '../suggestions/desktop/suggestions';
import SuggestionsMobile from '../suggestions/mobile/suggestionsMobile'


const FinancialHealth: React.FC<ICustomerPageProps> = ({ userID, pageNumber }) => {
    const classes = useStyles();

    const [err, setErr] = React.useState('');
    const dispatch = useDispatch();
    const { rentability, homework, financialHealth, loggedID, isPlanner } = useSelector((store: IStore) => ({
        rentability: store.customer.rentability,
        homework: store.customer.homework,
        financialHealth: store.customer.financialHealth,
        loggedID: store.loggedUser.currentUser.sub,
        isPlanner: store.loggedUser.currentUser['cognito:groups']?.[0] !== 'Customers',
    }));
    const [loading, setLoading] = React.useState(true);

    const userIDCompare: string = String(localStorage.getItem('userID'));;

    const userIDStorage = JSON.parse(localStorage.getItem("userID"));
    const currentStateStorage = JSON.parse(localStorage.getItem('financialHealth' + userIDCompare))?.currentState;
    const desiredStateStorage = JSON.parse(localStorage.getItem('financialHealth' + userIDCompare))?.desiredState;

    React.useEffect(() => {
        dispatch(SelectCurrentPage(pageNumber));
    }, []);

    React.useEffect(() => {
        const getDefaultRentability = async () => {
            try {
                const response = await api.get<IFinancialInfo>('/financialinfo');
                return response.data.profileGain.default;
            } catch (e) {
                console.error(e);
                return 0;
            }
        };

        getDefaultRentability();
        const fetchFinancial = async () => {
            if (
                localStorage.getItem('financialHealth' + userIDCompare) != undefined ||
                localStorage.getItem('financialHealth' + userIDCompare) != null
            ) {
                dispatch(
                    ChangeFinancialHealth(JSON.parse(String(localStorage.getItem('financialHealth' + userIDCompare))))
                );
                setLoading(false);

                let rentabilityValue = rentability;

                if (!rentability) {
                    // rentabilityValue = await getDefaultRentability();
                }
                let url = `/financialhealth?rentability=${rentabilityValue}`;
                if (userIDStorage) {
                    url += `&userID=${userIDStorage}`;
                } else {
                    url += `&userID=${userID}`;
                }

                const response = (await api.get<IFinancialHealth>(url)).data;

                if (String(localStorage.getItem('financialHealth' + userIDCompare)) !== JSON.stringify(response)) {
                    localStorage.setItem('financialHealth' + userIDCompare, JSON.stringify(response));
                    dispatch(ChangeFinancialHealth(response));
                }
            } else {
                let rentabilityValue = rentability;

                if (!rentability) {
                    // rentabilityValue = await getDefaultRentability();
                }
                let url = `/financialhealth?rentability=${rentabilityValue}`;
                if (userIDStorage) {
                    url += `&userID=${userIDStorage}`;
                } else {
                    url += `&userID=${userID}`;
                }

                const response = (await api.get<IFinancialHealth>(url)).data;
                localStorage.setItem('financialHealth' + userIDCompare, JSON.stringify(response));
                dispatch(ChangeFinancialHealth(response));
            }

            setLoading(false);
        };
        fetchFinancial();
    }, [rentability]);

    const yourNumber = () => {
        if (!desiredStateStorage) {
            return financialHealth.desiredState.neededForIndependence + financialHealth.desiredState.totalGoals;
        } else {
            return desiredStateStorage?.neededForIndependence + desiredStateStorage?.totalGoals;
        }
    }

    return (
        <div>
            {window.screen.width <= 767 ? (
                <LoadingPageMobile loading={loading}>
                    <div className={classes.group}>
                        <div className={classes.content}>
                            <CurrentStateMobile currentState={currentStateStorage || financialHealth.currentState} />
                            <FinancialIndependenceGoalsMobile FIGoals={financialHealth.financialIndependenceGoals} />
                            <DesiredStateMobile desiredState={desiredStateStorage || financialHealth.desiredState} />
                            <YourNumberMobile
                                userID={userID || loggedID}
                                isPlanner={isPlanner}
                                yourNumber={yourNumber()}
                            />
                        </div>
                    </div>
                </LoadingPageMobile>
            ) : (
                <LoadingPage loading={loading}>
                    <div className="container">
                        <div className="d-flex align-items-center">
                            <i className="fa-regular fa-heart me-2 mt-1"></i><h4 className="m-0">Saúde financeira</h4>
                        </div>
                        <div className={classes.group}>
                            <div className={classes.content}>
                                <div style={{ flexBasis: '50%' }}>
                                    <CurrentState currentState={currentStateStorage || financialHealth.currentState} />
                                </div>
                                <div style={{ width: '100%', flexBasis: '50%' }}>
                                    <DesiredState desiredState={desiredStateStorage || financialHealth.desiredState} />
                                    <YourNumber
                                        userID={userID || loggedID}
                                        isPlanner={isPlanner}
                                        yourNumber={yourNumber()}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </LoadingPage>
            )}
        </div>
    );
};

export default FinancialHealth;

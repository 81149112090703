import { Drawer,  List, ListItem, ListItemIcon, ListItemText } from '@material-ui/core';
import { useStyles } from './style';
import { ArrowBack } from '@material-ui/icons';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import menuOptions, { IPageDescriptor } from '../../../../../menuOptions';
import { SelectCurrentPage } from '../../../../../redux/actions/planningActions';
import { IStore } from '../../../../../redux/types/store';

const SideBar: React.FC<{ drawerWidth: string; onSignOut: () => void; useRedirect?: boolean }> = ({
    drawerWidth,
    onSignOut,
    useRedirect,
}) => {
    const classes = useStyles({ drawerWidth });

    const signOut = () => {
        onSignOut();
    };

    const [isDrawerOpen, setIsDrawerOpen] = useState(false);

    const selectedPageIndex = useSelector((store: IStore) => store.planning.customerPageIndex);
    const dispatch = useDispatch();

    const history = useHistory();

    const goToPage = (path: string, index: number) => {
        dispatch(SelectCurrentPage(index));
        history.push(path);
    };

    const getElement = (Element: React.FC<any>, props?: any) => {
        return <Element {...props} />;
    };

    const { group } = useSelector((store: IStore) => ({
        group: store.loggedUser.currentUser['cognito:groups']?.[0] || '',
    }));

    const isCustomers = group === 'Customers';

    function goToBB() {
        window.open('https://globusdigital.com.br/vida_icatu/#/home?pc=Walkr', '_blank')
    }
    function goToEfund() {
        window.open('https://globusdigital.com.br/consorcio/#/home?pc=Walkr', '_blank')
    }

    return (
        <>
            <Drawer
                className={classes.drawer}
                variant="permanent"
                classes={{
                    paper: classes.drawerPaper,
                }}
                anchor="left"
            >
                <List component="nav" className={classes.list}>
                    {isCustomers ? (
                        <>
                            {menuOptions.map((item: IPageDescriptor, index: number) =>
                                item.name !== 'Gestão de Riscos' && item.name !== 'Carteira & Investimentos' ? (
                                    <ListItem
                                        button
                                        className={classes.listItem}
                                        classes={{ root: classes.buttonRoot }}
                                        //@ts-ignore
                                        onClick={() => {
                                            useRedirect
                                                ? goToPage(item.url, index)
                                                : goToPage('/planner/planningresult' + item.url, index);
                                        }}
                                    >
                                        <ListItemIcon className={classes.svgIcon}>
                                            {getElement(item.Icon, {
                                                width: 16,
                                                height: 16,
                                                color: index === selectedPageIndex ? '#0DF2FF' : undefined,
                                            })}
                                        </ListItemIcon>
                                        <ListItemText
                                            classes={{
                                                primary:
                                                    index === selectedPageIndex
                                                        ? classes.textSelected
                                                        : classes.listText,
                                            }}
                                            primary={item.name}
                                        />
                                    </ListItem>
                                ) : null
                            )}
                            {/* @ts-ignore */}
                            <ListItem button onClick={goToEfund} classes={{ root: classes.buttonRoot }}>
                                <ListItemIcon className={classes.svgIcon}>
                                    <i className="fa-regular fa-dollar-sign"></i>
                                </ListItemIcon>
                                <span style={{ fontSize: '15px' }}>
                                    Consórcio
                                </span>
                            </ListItem>
                            {/* @ts-ignore */}
                            <ListItem button onClick={goToBB} classes={{ root: classes.buttonRoot }}>
                                <ListItemIcon className={classes.svgIcon}>
                                    <i className="fa-sharp fa-solid fa-store"></i>
                                </ListItemIcon>
                                <span style={{ fontSize: '15px' }}>
                                    Seguro de Vida
                                </span>
                            </ListItem>
                            {/* @ts-ignore */}
                            <ListItem button onClick={signOut} classes={{ root: classes.buttonRoot }}>
                                <ListItemIcon className={classes.svgIcon}>
                                    <ArrowBack style={{ color: 'white' }} />
                                </ListItemIcon>
                                Sair
                            </ListItem>
                        </>
                    ) : (
                        <>
                            {menuOptions.map((item: IPageDescriptor, index: number) => (
                                <ListItem
                                    button
                                    className={classes.listItem}
                                    classes={{ root: classes.buttonRoot }}
                                    //@ts-ignore
                                    onClick={() => {
                                        useRedirect
                                            ? goToPage(item.url, index)
                                            : goToPage('/planner/planningresult' + item.url, index);
                                    }}
                                >
                                    <ListItemIcon className={classes.svgIcon}>
                                        {getElement(item.Icon, {
                                            width: 16,
                                            height: 16,
                                            color: index === selectedPageIndex ? '#0DF2FF' : undefined,
                                        })}
                                    </ListItemIcon>
                                    <ListItemText
                                        classes={{
                                            primary:
                                                index === selectedPageIndex ? classes.textSelected : classes.listText,
                                        }}
                                        primary={item.name}
                                    />
                                </ListItem>
                            ))}
                            {/* @ts-ignore */}
                            <ListItem button onClick={goToEfund} classes={{ root: classes.buttonRoot }}>
                                <ListItemIcon className={classes.svgIcon}>
                                    <i className="fa-regular fa-dollar-sign"></i>
                                </ListItemIcon>
                                <span style={{ fontSize: '15px' }}>
                                    Consórcio
                                </span>
                            </ListItem>
                            {/* @ts-ignore */}
                            <ListItem button onClick={goToBB} classes={{ root: classes.buttonRoot }}>
                                <ListItemIcon className={classes.svgIcon}>
                                    <i className="fa-sharp fa-solid fa-store"></i>
                                </ListItemIcon>
                                <span style={{ fontSize: '15px' }}>
                                    Seguro de Vida
                                </span>
                            </ListItem>
                            {/* @ts-ignore */}
                            <ListItem button onClick={signOut} classes={{ root: classes.buttonRoot }}>
                                <ListItemIcon className={classes.svgIcon}>
                                    <ArrowBack style={{ color: 'white' }} />
                                </ListItemIcon>
                                Sair
                            </ListItem>
                        </>
                    )}
                </List>
            </Drawer>
        </>
    );
};

export default SideBar;

import { Button, Card, CardActions, CardContent, CircularProgress, Grid, Typography, withWidth } from '@material-ui/core';
import React from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { SetExit, SetLogout } from '../../../redux/actions/planningActions';
import { useStyles } from './style';
import { StepCardProps } from './types';
import { StepDone } from '../../../assets/icons'
import { StepTodo } from '../../../assets/icons'
import { ClientIcon } from '../../../assets/icons'
import { GoalsIcon } from '../../../assets/icons'
import { FinancingIcon } from '../../../assets/icons'
import { FixedCostIcon } from '../../../assets/icons'
import { IncomingIcon } from '../../../assets/icons'
import { PatrimonyIcon } from '../../../assets/icons'
import { RiskIcon } from '../../../assets/icons'
import { SuggestionsIcon } from '../../../assets/icons'
import { ProjectIcon } from '../../../assets/icons'

const Invest = '/invest.svg';

const Icon = (card: string) => {
    switch (card) {
        case 'client':
            return <ClientIcon />
        case 'goals':
            return <GoalsIcon />
        case 'financing':
            return <FinancingIcon />
        case 'fixedCost':
            return <FixedCostIcon />
        case 'incoming':
            return <IncomingIcon />
        case 'patrimony':
            return <PatrimonyIcon />
        case 'risk':
            return <RiskIcon />
        case 'suggestions':
            return <SuggestionsIcon />
        case 'project':
            return <ProjectIcon />

    }
}

const IconResolver = (done: boolean, loading: boolean) => {
    if (loading) return <CircularProgress style={{ color: '#022536' }} />;
    if (done) return <StepDone />;
    else return <StepTodo />;
};

const StepCard: React.FC<StepCardProps> = ({
    className,
    cardType,
    children,
    done,
    title,
    redirectPath,
    width,
    loading,
    CustomIcon,
    negative,
    backgroundImage
}) => {
    const classes = useStyles();
    const history = useHistory();

    let IconElem = IconResolver(done, loading);

    const buttonClick = () => history.push(redirectPath);

    if (CustomIcon) IconElem = <img src={Invest} alt="A preencher" width="41px" height="41px"></img>;

    const startClick = () => {
        if (redirectPath !== '/planner/createuser' && redirectPath !== '/planner/select') {
            dispatch(SetExit());
        } else {
            dispatch(SetLogout());
        }
        history.push(redirectPath);
    };



    const dispatch = useDispatch();

    const [minWidth, setMinWidth] = React.useState('23vw');

    React.useEffect(() => {
        switch (width) {
            case 'sm':
            case 'md':
                setMinWidth('32vw');
                break;
            default:
                setMinWidth('23vw');
                break;
        }
    }, [width]);

    return (

        <div className={className}>
            {CustomIcon ?
                <Card
                    className={negative ? backgroundImage !== 'project' && backgroundImage !== 'suggestions' ? classes.negative : classes.root : backgroundImage === 'project' ? classes.rootProject : classes.rootSuggestion}
                    style={{ minWidth, height: '100%', border: 'solid 1px #d3c9c9' }}
                    //@ts-ignore
                    onClick={() => buttonClick()}
                >
                    <CardContent classes={{ root: classes.cardContent }} className="p-0 m-3 mb-0 position-relative">
                        <Grid item className={classes.check}>
                            {IconElem}
                        </Grid>
                        <Grid item className={`${classes.icon} position-absolute`}>
                            {Icon(backgroundImage)}
                        </Grid>
                        <Grid container className="d-flex justify-content-center text-center">
                            <Grid item xs={12}>
                                <Typography className={classes.cardTypeLabel} variant="overline" style={{ color: negative ? '#034663' : 'white' }}>
                                    {cardType}
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography className={classes.cardHeader} variant="h4" style={{ color: negative ? '#034663' : 'white' }}>
                                    {title}
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography className={classes.cardBody} style={{ color: negative ? '#034663' : 'white' }}>
                                    {children}
                                </Typography>
                            </Grid>
                        </Grid>
                    </CardContent>
                    <CardActions className={classes.cardActions}>
                        {/* @ts-ignore */}
                        <Button
                            id="noHover"
                            size="medium"
                            color="primary"
                            onClick={() => buttonClick()}
                            className={negative ? classes.cardWhite : classes.cardBlue}
                        >
                            Começar
                        </Button>
                    </CardActions>
                </Card>

                :

                <Card
                    className={negative ? backgroundImage !== 'project' && backgroundImage !== 'suggestions' ? classes.negative : classes.root : backgroundImage === 'project' ? classes.rootProject : classes.rootSuggestion}
                    style={{ minWidth, height: '100%', border: 'solid 1px #d3c9c9' }}
                    //@ts-ignore
                    onClick={() => startClick()}
                >
                    <CardContent classes={{ root: classes.cardContent }} className="p-0 m-3 mb-0 position-relative">
                        <Grid item className={classes.check}>
                            {IconElem}
                        </Grid>
                        <Grid item className={`${classes.icon} position-absolute`}>
                            {Icon(backgroundImage)}
                        </Grid>
                        <Grid container className="d-flex justify-content-center text-center">
                            <Grid item xs={12}>
                                <Typography className={classes.cardTypeLabel} variant="overline" style={{ color: negative ? '#034663' : 'white' }}>
                                    {cardType}
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography className={classes.cardHeader} variant="h4" style={{ color: negative ? '#034663' : 'white' }}>
                                    {title}
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography className={classes.cardBody} style={{ color: negative ? '#034663' : 'white' }}>
                                    {children}
                                </Typography>
                            </Grid>
                        </Grid>
                    </CardContent>
                    <CardActions className={classes.cardActions}>
                        {/* @ts-ignore */}
                        <Button
                            id="noHover"
                            size="medium"
                            color="primary"
                            onClick={() => startClick()}
                            className={negative ? classes.cardWhite : classes.cardBlue}
                        >
                            Começar
                        </Button>
                    </CardActions>
                </Card>

            }

        </div>

    );
};

export default withWidth()(StepCard);
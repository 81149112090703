import { createStyles, makeStyles, Theme } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        formControl: {
            '@media (max-width: 767px)': {
                alignItems: 'baseline',
            },
            '@media(min-with: 768px)': {
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'baseline',
                marginRight: theme.spacing(3),
            },
        },
        select: {
            minWidth: 300,
            flexGrow: 1,
        },
    })
);
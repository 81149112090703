import Grid from '@material-ui/core/Grid';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, Route, Switch, useHistory } from 'react-router';
import menuOptions from '../../../menuOptions';
import { ResetCustomer } from '../../../redux/actions/customerActions';
import { IStore } from '../../../redux/types/store';
import InvalidPlanning from '../../customerPages/InvalidPlanning/invalidPlanning';


import SideBar from '../../global/navigation/sideBar/desktop/sideBar';

import { useStyles } from './style';

import NavbarMobile from '../../global/navigation/navbar/mobile/navbarMobile';
import Navbar from '../../global/navigation/navbar/desktop/navbar';

const PlanningResult: React.FC<any> = ({ customerName }) => {
    const { userID, planningDate } = useSelector((store: IStore) => ({
        userID: store.planning.customerUserInfo.userID,
        planningDate: store.customer.financialHealth.planningDate,
    }));


    const history = useHistory();
    const dispatch = useDispatch();

    React.useEffect(() => {
        dispatch(ResetCustomer());
    }, []);

    if (!userID) {
        history.push('/');
    }

    const classes = useStyles();

    return (
        <>
            {
                window.screen.width <= 767 ? (
                    <>
                        <NavbarMobile planningDate={planningDate} drawerWidth={'15%'} customerName={customerName} />
                        <Grid item xs={12} lg={12} xl={12} className={classes.grid}>
                            <div className={classes.wrapper}>
                                <Switch>
                                    {menuOptions.map(({ Component, ...item }, i) => (
                                        <Route path={'/planner/planningResult' + item.url}>
                                            <Component pageNumber={i} userID={userID} />
                                        </Route>
                                    ))}
                                    <Route path="/planner/planningResult/invalid_planning">
                                        <InvalidPlanning />
                                    </Route>
                                    <Redirect from="/planner/planningresult/" to="/planner/planningResult/financialhealth" />
                                </Switch>
                            </div>
                        </Grid>
                    </>
                ) : (
                    <Grid item xs={12} lg={12} xl={12} className={classes.grid}>
                        <Navbar planningDate={planningDate} drawerWidth={'15%'} customerName={customerName} />

                        <SideBar onSignOut={() => history.push('/planner')} drawerWidth={'15%'} />
                        <div className={classes.wrapper}>
                            <Switch>
                                {menuOptions.map(({ Component, ...item }, i) => (
                                    <Route path={'/planner/planningResult' + item.url}>
                                        <Component pageNumber={i} userID={userID} />
                                    </Route>
                                ))}
                                <Route path="/planner/planningResult/invalid_planning">
                                    <InvalidPlanning />
                                </Route>
                                <Redirect from="/planner/planningresult/" to="/planner/planningResult/financialhealth" />
                            </Switch>

                        </div>
                    </Grid>
                )
            }
        </>
    );
};
export default PlanningResult;

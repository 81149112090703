import { Button, createStyles, Grid, IconButton, makeStyles, Theme, Typography } from '@material-ui/core';
import { red } from '@material-ui/core/colors';
import { Delete } from '@material-ui/icons';
import React from 'react';
import CreatableSelect from 'react-select/creatable';
import { GreyedDateInput, GreyedTextInput } from '../../../global/inputs/GreyedInput';
import CurrencyInput from '../../../global/masks/currencyMaskedInput';
import { costSelectOptions, ICostChange } from '../types';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        inputLabel: {
            padding: '1em 0 0.5em',
        },
        perspectiveLine: {
            display: 'flex',
            justifyContent: 'flex-start',
            alignItems: 'flex-end',
        },
        line: {
            display: 'flex',
            justifyContent: 'flex-start',
            alignItems: 'flex-end',
        },
        removeButton: {
            backgroundColor: red[400],
            color: '#ffffff',
            '&:hover': { backgroundColor: red[800] },
        },
        raised: {
            backgroundColor: '#A1D830',
            color: '#ffffff',
            '&:hover': { color: '#000000' },
        },
        reduction: {
            backgroundColor: red[400],
            color: '#ffffff',
            '&:hover': { color: '#000000' },
        },
        unraised: {
            backgroundColor: '#E1E1E1',
        },
    })
);

interface ICostChangeProps {
    val: ICostChange;
    setChange: (item: ICostChange) => void;
    removeSelf: () => void;
    isLast: boolean;
    autoFocus: boolean;
    addLine?: () => void;
}

const CostChangeEntry: React.FC<ICostChangeProps> = ({ val, setChange, autoFocus, isLast, removeSelf, addLine }) => {
    const classes = useStyles();
    const isRaise = () => {
        if (isNaN(val.value)) return true;
        if (val.value >= 0) return true;
        return false;
    };
    const [raise, setRaise] = React.useState(isRaise());
    const [value, setValue] = React.useState(val.value.toString().replace('-', ''));
    React.useEffect(() => {
        if (val.value === 0) {
            setValue('');
        }
    }, [val.value, setValue]);

    const unmask = (v: string) => Math.abs(parseFloat(v.replace('.', '').replace(',', '.'))) * (raise ? 1 : -1);

    const setName = (name: string) => setChange({ ...val, name });

    const setDate = (dateEv: Date) => {
        setChange({ ...val, date: dateEv });
    };

    const changeValue = (v: string) => {
        setValue(v);
        const valueUnmasked = unmask(v);
        setChange({ ...val, value: valueUnmasked });
    };

    // adding value to dependency array would cause
    // this effect to run infinitely
    // converting props to internal state somehow
    // causes KeyboardDatePicker to hold the state between
    // incoming pages, I wouldn't recomend either of these "fixes"
    React.useEffect(() => {
        // repeating function in effect to avoid its dependency
        const unmask_ = (v: string) => Math.abs(parseFloat(v.replace('.', '').replace(',', '.'))) * (raise ? 1 : -1);

        setChange({ ...val, value: unmask_(value) });
    }, [raise, value, setRaise]);

    const [selected, setSelected] = React.useState({ value: val.name, label: val.name });

    const handleChange = (newValue: any, actionMeta: any) => {
        setName(newValue ? newValue.label : '');
        setSelected(newValue ? newValue : { value: '', label: '' });
    };

    return (
        <Grid alignItems="flex-end" justify="space-between" container spacing={1}>
            <Grid item xs={3}>
                <Typography className={classes.inputLabel} variant="body2">
                    Custo
                </Typography>
                <CreatableSelect
                    formatCreateLabel={input => 'Criar ' + input}
                    isClearable
                    onChange={handleChange}
                    options={costSelectOptions}
                    value={selected}
                />
                {/* <GreyedTextInput
                    autoFocus={isLast && autoFocus}
                    color="primary"
                    variant="outlined"
                    size="small"
                    placeholder="Descrição do custo"
                    InputLabelProps={{
                        shrink: true,
                    }}
                    value={val.name}
                    onChange={event => setName(event.target.value as string)}
                    fullWidth
                /> */}
            </Grid>
            <Grid item xs={3}>
                <Typography className={classes.inputLabel} variant="body2">
                    Data
                </Typography>
                <GreyedDateInput
                    defaultValue={new Date()}
                    variant="inline"
                    format="dd/MM/yyyy"
                    margin="normal"
                    value={val.date}
                    onChange={dateEvt => setDate(dateEvt as Date)}
                    KeyboardButtonProps={{
                        'aria-label': 'change-date',
                    }}
                    inputVariant="outlined"
                    size="small"
                    style={{ minWidth: '50%' }}
                />
            </Grid>
            <Grid item xs={2}>
                <Typography className={classes.inputLabel} variant="body2">
                    Valor mensal
                </Typography>
                <GreyedTextInput
                    color="primary"
                    variant="outlined"
                    size="small"
                    type="numeric"
                    placeholder="0.00"
                    InputProps={{
                        inputComponent: CurrencyInput as any,
                        startAdornment: <div style={{ margin: '0 8px' }}>R$</div>,
                    }}
                    InputLabelProps={{
                        shrink: true,
                    }}
                    value={value}
                    onChange={event => changeValue(event.target.value as string)}
                    onFocus={e => {
                        const v = e.target.value;
                        e.target.value = '';
                        e.target.value = v;
                    }}
                    fullWidth
                />
            </Grid>
            <Grid item xs={2}>
                <div className={classes.line}>
                    {/* @ts-ignore */}
                    <Button
                        classes={{ root: raise ? classes.raised : classes.unraised }}
                        focusRipple={false}
                        onClick={() => setRaise(true)}
                    >
                        Aumento
                    </Button>
                    {/* @ts-ignore */}
                    <Button
                        className={!raise ? classes.reduction : classes.unraised}
                        focusRipple={false}
                        onClick={() => setRaise(false)}
                        style={{ marginLeft: 8 }}
                    >
                        Redução
                    </Button>
                </div>
            </Grid>
            <Grid item xs={1} justify="flex-end">
                {/* @ts-ignore */}
                <IconButton className={classes.removeButton} onClick={removeSelf}>
                    <Delete />
                </IconButton>
            </Grid>
            {isLast ? (
                <Grid item xs={3}>
                    {/* @ts-ignore */}
                    <Button
                        size="small"
                        variant="contained"
                        disableElevation
                        color="primary"
                        style={{ color: 'white' }}
                        fullWidth
                        onClick={addLine}
                    >
                        Adicionar
                    </Button>
                </Grid>
            ) : null}
        </Grid>
    );
};
export default CostChangeEntry;

import { AppBar, ThemeProvider, Toolbar, Typography, useTheme } from '@material-ui/core';
import { useStyles, toolbarTheme } from './style';
import moment from 'moment';
import React from 'react';
import api from '../../../../../apis/dreamrichapi';
import { defaultProfileGain, IFinancialInfo } from '../../../../toolbarInfo/types';
import ValueColorText from '../../../displays/valueColorText/valueColorText';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import { useSelector } from 'react-redux';
import { IStore } from '../../../../../redux/types/store';
import { useHistory } from 'react-router';



const NavbarMobile: React.FC<{ drawerWidth: string; customerName: string; planningDate: Date }> = ({
    drawerWidth,
    customerName,
    planningDate,
}) => {
    const classes = useStyles({ drawerWidth });
    const theme = useTheme();
    const [financialInfo, setFinancialInfo] = React.useState<IFinancialInfo>({
        cdi: 0,
        ipca: 0,
        profileGain: defaultProfileGain,
        lastRefresh: new Date(),
        realGain: 0,
    });

    const pad = (n: number) => {
        return n < 10 ? '0' + n : n;
    };

    React.useEffect(() => {
        api.get<IFinancialInfo>('/financialinfo')
            .then(response => {
                setFinancialInfo(response.data);
            })
            .catch(e => {
                console.log(e);
            });
    }, []);

    const history = useHistory()

    const { group } = useSelector((store: IStore) => ({
        group: store.loggedUser.currentUser['cognito:groups']?.[0] || '',
    }));

    const isCustomers = group === 'Customers';

    const backToMenu = () => {
        if (isCustomers) history.push('/menuMobile')
        else history.push('/planner/menuMobile')
    }

    const NewFinancialData = (title: string, value: number, institute: string, addcomma?: boolean) => (
        <div className={classes.line} style={{ marginLeft: theme.spacing(1) }}>
            <Typography className={classes.whiteInfos}>{title.toUpperCase()}</Typography>
            <ValueColorText type="percentage" value={value * 100} textColor={'#AAAAAA'}>
                a.a. {institute && `(${institute.toUpperCase()})`}
                {addcomma && ','}
            </ValueColorText>
        </div>
    );



    return (
        <nav className='position-fixed w-100 justify-content-between' style={{ height: '6%', backgroundColor: '#065875', zIndex: 100 }} >
            <div className="d-flex position-absolute gap-5 ms-5">
                {/* @ts-ignore */}
                <IconButton
                    onClick={backToMenu}
                    edge="start"
                    color="inherit"
                    aria-label="menu"
                >
                    <MenuIcon style={{ color: '#FFF' }} />
                </IconButton>
                <h6 className='text-capitalize text-white mt-3'>
                    {customerName.toLowerCase()}
                </h6>
            </div>
        </nav>
    );
};

export default NavbarMobile;

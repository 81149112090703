import { Divider, Grid, Paper, Typography } from '@material-ui/core';
import React from 'react';
import DisplayLine from '../../../global/displays/displayLine/displayLine';
import formatter from '../../../global/formater';
import { useStyles } from './style'

interface IForFamilyProps { monthlyIncoming: number; duration: number; necessaryPatrimony: number; totalInvested: number; diff: number; }

const ForFamily: React.FC<IForFamilyProps> = ({ monthlyIncoming, duration, necessaryPatrimony, totalInvested }) => {
    const classes = useStyles();
    const diff = necessaryPatrimony - totalInvested;
    return (
        <>
            {window.screen.width <= 767 ? (

                <div className={`${classes.paperPadding} d-column container`}>
                    <h6 className='fw-bold text-center'>Para a sua família</h6>

                    <div className={`${classes.container}d-flex`}>
                        <div className='d-flex justify-content-around mt-3'>
                            <h6>Renda mensal</h6>
                            <Typography variant="h5" color="primary">
                                {formatter.format(monthlyIncoming)}
                            </Typography>
                        </div>
                        <div className='d-flex justify-content-around mt-3'>
                            <h6>Durante</h6>
                            <Typography variant="h5" color="primary">
                                {duration} anos
                            </Typography>
                        </div>
                        <div className='d-flex justify-content-around mt-3'>
                            <h6>Patrimônio Necessário</h6>
                            <div>
                                <Typography variant="h5" color="primary">
                                    {
                                        necessaryPatrimony > 0
                                            ? formatter.format(necessaryPatrimony)
                                            : formatter.format(0)
                                    }
                                </Typography>
                            </div>
                        </div>
                        <div className='d-flex justify-content-around mt-3'>
                            <h6>Investimentos + imóveis + seguro</h6>
                            <Typography variant="h5" color="primary">
                                {formatter.format(totalInvested)}
                            </Typography>
                        </div>
                    </div>
                    <div className={classes.display}>
                        <DisplayLine label="Necessário alavancar sua proteção em" value={diff > 0 ? diff : 0}></DisplayLine>
                    </div>
                </div>
            ) : (
                <Paper className={classes.paperPadding}>
                    <Typography variant="h6">Para a sua família</Typography>

                    <Grid className={classes.container}>
                        <Grid item xs={4}>
                            <Typography>Renda mensal</Typography>
                            <Typography variant="h5" color="primary">
                                {formatter.format(monthlyIncoming)}
                            </Typography>
                        </Grid>
                        <Grid style={{ maxWidth: '16px' }} item xs={1}>
                            <div className={classes.divider} />
                        </Grid>
                        <Grid item xs={7}>
                            <Typography>Durante</Typography>
                            <Typography variant="h5" color="primary">
                                {duration} anos
                            </Typography>
                        </Grid>
                        <Grid className={classes.horizontalDivider} item xs={12}>
                            <Divider />
                        </Grid>
                        <Grid item container justify="space-between" xs={6}>
                            <Grid item xs={12}>
                                <Typography>Patrimônio necessário</Typography>
                            </Grid>
                            <Grid style={{ alignSelf: 'flex-end' }} item>
                                <Typography align="left" variant="h5" color="primary">
                                    {
                                        necessaryPatrimony > 0
                                            ? formatter.format(necessaryPatrimony)
                                            : formatter.format(0)
                                    }
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid style={{ maxWidth: '16px' }} item xs={1}>
                            <div className={classes.divider} />
                        </Grid>
                        <Grid item xs={4}>
                            <Typography>Investimentos + imóveis + seguro</Typography>
                            <Typography variant="h5" color="primary">
                                {formatter.format(totalInvested)}
                            </Typography>
                        </Grid>
                    </Grid>
                    <div className={classes.display}>
                        <DisplayLine label="Necessário alavancar sua proteção em" value={diff > 0 ? diff : 0}></DisplayLine>
                    </div>
                </Paper>
            )}

        </>
    );
};
export default ForFamily;

import { Button, CircularProgress, Fade, InputAdornment, Modal, TextField, Typography, } from '@material-ui/core';
import React, { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import Select from 'react-select';
import api from '../../../apis/dreamrichapi';
import { ChangeCustomerUserInfo, ResetUserInfo } from '../../../redux/actions/planningActions';
import { ICustomerUserInfo } from '../../../redux/types/formPlanning';
import { IStore } from '../../../redux/types/store';
import { IPlannerPageProps } from '../types';
import { IListResponse, IUserEntry } from './clientEntry';
import CustomerTable from './customerTable/customerTable';
import { useStyles } from './style';

interface ISelectClientProps extends IPlannerPageProps {
    setSelectedUser?: (user: string) => void;
    customcustomers: boolean;
    profileTitle: string;
}

let PlannerID = "";

const SelectClient: FC<ISelectClientProps> = ({ setSelectedUser, changeTitle, profileTitle, customcustomers }) => {
    const classes = useStyles();
    const [customers, setCustomers] = useState<IUserEntry[]>([]);
    const [planners, setPlanners] = useState<Map<string, IUserEntry>>(new Map());
    // if you need, declare err to use it [err, setErr]
    const [, setErr] = useState('');
    const [openChangePlanner, setOpenChangePlanner] = useState(false);
    const [openDeletePlanner, setOpenDeletePlanner] = useState(false);
    const [openDeleteClient, setOpenDeleteClient] = useState(false);
    const [error, setError] = useState(false);
    const [confirm, setConfirm] = useState('');
    const [loading, setLoading] = useState(false);
    const [modalLoading, setModalLoading] = useState(false);

    const { group, myUserID, myUsername } = useSelector((store: IStore) => ({
        group: store.loggedUser.currentUser['cognito:groups']?.[0] || '',
        myUserID: store.loggedUser.currentUser.sub,
        myUsername: store.loggedUser.currentUser['cognito:username'],
    }));

    const isAdmin = group === 'admin';
    const [selectDisabled, setSelectDisabled] = useState(false);

    type Options = {
        value: IUserEntry;
        label: string;
        labelUser: string;
    };

    const options = Array.from(planners.values())
        .map(
            (planner): Options => ({
                value: planner,
                label: planner.email,
                labelUser: planner.user,
            })
        )
        .sort((a, b) => a.labelUser.localeCompare(b.labelUser));

    const dispatch = useDispatch();
    const [showDelete, setShowDelete] = useState(false);

    const fetchList = async () => {
        setLoading(true);

        const tableStorage = localStorage.getItem('local-storage-table');

        localStorage.clear();

        if (JSON.parse(localStorage.getItem('local-storage-table'))) {
            const storageTable = JSON.parse(tableStorage)
            setCustomers(storageTable.data.customers);
            setPlanners(new Map(storageTable.data.planners.map(item => [item.user, item])));
            setLoading(false);
            localStorage.setItem('local-storage-table',storageTable)
        }
        
        try {
            const res = await api.get<IListResponse>('/listusers');
            setCustomers(res.data.customers);
            setPlanners(new Map(res.data.planners.map(item => [item.user, item])));

            localStorage.setItem('local-storage-table', JSON.stringify(res))
        } catch (e) {
            if (e.response) {
                setErr(e.response.data);
            } else {
                setErr('Erro interno');
            }
        }
        setLoading(false);
    };

    useEffect(() => {
        if (profileTitle === 'admin') changeTitle('PORTAL DO ADMIN');
        else changeTitle('PORTAL DO ASSESSOR');

        fetchList();
    }, []);

    const [editUser, setEditUser] = useState<IUserEntry | null>(null);

    const history = useHistory();
    const selectUser = (user: IUserEntry) => {
        dispatch(ChangeCustomerUserInfo(user as ICustomerUserInfo));

        localStorage.setItem("userSelectedRedux", JSON.stringify(user))

        if (setSelectedUser) {
            setSelectedUser(user.userID);
        } else {
            history.push('/planner');
        }
    };

    const submitDeletePlanner = async () => {
        setModalLoading(true);
        if (confirm === 'confirmar') {
            setError(false);
            try {
                const params = new URLSearchParams();
                params.append('plannerID', PlannerID);

                await api.delete('/deleteplanner?' + params.toString());

                await fetchList();
                setOpenDeletePlanner(false);
            } catch (e) {
                setError(true);
                console.log(e?.response?.data);
            }
        } else {
            setError(true);
        }
        setModalLoading(false);
    };

    const submitDeleteClient = async () => {
        setModalLoading(true);
        if (confirm === 'confirmar') {
            setError(false);
            try {
                const params = new URLSearchParams();
                params.append('username', editUser?.userID || '');

                await api.delete('/deleteuser?' + params.toString());

                await fetchList();
                setOpenDeleteClient(false);
            } catch (e) {
                setError(true);
                console.log(e?.response?.data);
            }
        } else {
            setError(true);
        }
        setModalLoading(false);
    };

    useEffect(() => {
        setSelectDisabled(!isAdmin && editUser?.plannerUser !== myUsername);
    }, [editUser]);

    const handleCloseChangePlanner = () => {
        setOpenChangePlanner(false);
        setEditUser(null);
        setShowDelete(false);
        setSelectDisabled(false);
    };

    const handleClosePlanner = () => {
        setOpenDeletePlanner(false);
        setShowDelete(false);
        setEditUser(null);
        setSelectDisabled(false);
    };

    const handleCloseDeleteClient = () => {
        setOpenDeleteClient(false);
        setShowDelete(false);
        setEditUser(null);
        setSelectDisabled(false);
    };

    const handleChangePlanner = async (newValue: any, actionMeta: any) => {
        if (newValue && actionMeta.action === 'select-option') {
            const cast = newValue as Options;
            if (isAdmin) {
                setModalLoading(true);
                try {
                    await api.post('/transfercustomer', {
                        toId: cast.value.userID,
                        toUsername: cast.value.user,
                        customerId: editUser?.userID || '',
                    });

                    setOpenChangePlanner(false);
                    await fetchList();
                    if (!isAdmin) {
                        setSelectDisabled(true);
                    }
                } catch (e) {
                    console.log(e);
                }
                setModalLoading(false);
            }

        }
    };

    const handleDeletePlanner = async (newValue: any, actionMeta: any) => {
        if (newValue && actionMeta.action === 'select-option') {
            const cast = newValue as Options;
            if (isAdmin) PlannerID = cast.value.userID
        }
    };

    const onChangePlanner = (user: IUserEntry) => {
        setOpenChangePlanner(true);
        setEditUser(user);
    };

    const onDeleteClient = (user: IUserEntry) => {
        setOpenDeleteClient(true);
        setEditUser(user);
    };

    const onDeletePlanner = () => setOpenDeletePlanner(true);

    return (
        <>
            <CustomerTable
                fetchList={fetchList}
                customers={customers}
                options={options}
                loading={loading}
                openModalChangePlanner={onChangePlanner}
                openModalDeletePlanner={onDeletePlanner}
                openDeleteClient={onDeleteClient}
                selectUser={selectUser}
            />

            {/* TROCAR ASSESSOR */}
            <Modal
                open={openChangePlanner}
                onClose={handleCloseChangePlanner}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
            >
                <div className={classes.paper}>
                    <Typography>Usuário: {editUser?.fullName || ''}</Typography>
                    <Typography>Email: {editUser?.email || ''}</Typography>
                    <div className={classes.line}>
                        <Typography>Assessor: </Typography>
                        <Select
                            className={classes.select}
                            options={options}
                            defaultValue={options.filter(opt => opt.label === editUser?.plannerUser)?.[0]}
                            isClearable
                            isDisabled={selectDisabled}
                            onChange={handleChangePlanner}
                            isLoading={modalLoading}
                            placeholder="Procurar Assessor"
                        ></Select>
                    </div>
                </div>
            </Modal>

            {/* DELETAR ASSESSOR  */}
            <Modal
                open={openDeletePlanner}
                onClose={handleClosePlanner}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
            >
                <div className={classes.paper}>
                    <div className={classes.line}>
                        <Typography>Assessor: </Typography>
                        <Select
                            className={classes.select}
                            options={options}
                            isClearable
                            isDisabled={selectDisabled}
                            onChange={handleDeletePlanner}
                            isLoading={modalLoading}
                            placeholder="Procurar Assessor"
                        ></Select>
                    </div>
                    <div className={isAdmin ? classes.noPaddingLine : ''} hidden={!isAdmin}>
                        {/* @ts-ignore */}
                        <Button className={classes.deleteButton} onClick={() => setShowDelete(true)}>
                            Deletar
                        </Button>
                        <Fade in={showDelete}>
                            <div className={classes.modalInput}>
                                <form
                                    onSubmit={e => {
                                        e.preventDefault();
                                        submitDeletePlanner();
                                    }}
                                    className={classes.noPaddingLine}
                                >
                                    <div>
                                        <Typography className='mb-2'>Digite "confirmar" abaixo </Typography>

                                        <TextField
                                            size="small"
                                            value={confirm}
                                            error={error}
                                            onChange={e => setConfirm(e.target.value)}
                                            variant="outlined"
                                            disabled={modalLoading}
                                            placeholder="confirmar"
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        {modalLoading && <CircularProgress size={20} />}
                                                    </InputAdornment>
                                                ),
                                            }}
                                        ></TextField>
                                    </div>
                                    <Button type="submit" style={{ marginLeft: 6 }} color="primary">
                                        OK
                                    </Button>
                                </form>
                            </div>
                        </Fade>
                    </div>
                </div>
            </Modal>

            {/* DELETAR CLIENTE  */}
            <Modal
                open={openDeleteClient}
                onClose={handleCloseDeleteClient}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
            >
                <div className={classes.paper}>
                    <Typography>Usuário: {editUser?.fullName || ''}</Typography>
                    <Typography>Email: {editUser?.email || ''}</Typography>
                    <div className={isAdmin ? classes.noPaddingLine : ''} hidden={!isAdmin}>

                        {/* @ts-ignore */}
                        <Button className={classes.deleteButton} onClick={() => setShowDelete(true)}>
                            Deletar
                        </Button>
                        <Fade in={showDelete}>
                            <div className={classes.modalInput}>
                                <form
                                    onSubmit={e => {
                                        e.preventDefault();
                                        submitDeleteClient();
                                    }}
                                    className={classes.noPaddingLine}
                                >
                                    <div>
                                        <Typography className='mb-2'>Digite "confirmar" abaixo </Typography>

                                        <TextField
                                            size="small"
                                            value={confirm}
                                            error={error}
                                            onChange={e => setConfirm(e.target.value)}
                                            variant="outlined"
                                            disabled={modalLoading}
                                            placeholder="confirmar"
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        {modalLoading && <CircularProgress size={20} />}
                                                    </InputAdornment>
                                                ),
                                            }}
                                        ></TextField>
                                    </div>
                                    <Button type="submit" style={{ marginLeft: 6 }} color="primary">
                                        OK
                                    </Button>
                                </form>
                            </div>
                        </Fade>
                    </div>
                </div>
            </Modal>
        </>
    );
};
export default SelectClient;
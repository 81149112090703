import { Grid, Typography } from '@material-ui/core';
import React from 'react';
import SuccessionCard from '../successionCard/successionCard';
import { ISuccessionCosts } from '../types';
import { useStyles } from './style'

interface ISuccessionCostProps extends ISuccessionCosts { }

const SuccessionCost: React.FC<ISuccessionCostProps> = ({ current, future, currentTaxes, futureTaxes }) => {
    const classes = useStyles();

    return (
        <>
            {window.screen.width <= 767 ? (
                <div className=''>
                    <h5>Custos de sucessão (seguro vitalício)</h5>
                    <div className='container d-column'>
                        <div>
                            <SuccessionCard patrimonyTotal={current} taxes={currentTaxes} type="current" />
                        </div>
                        <div>
                            <SuccessionCard patrimonyTotal={future} taxes={futureTaxes} type="future" />
                        </div>
                    </div>
                </div>
            ) : (
                <div className=''>
                    <Typography variant="h5">Custos de sucessão (seguro vitalício) </Typography>
                    <Grid container spacing={2} className={classes.content}>
                        <Grid item xs={6}>
                            <SuccessionCard patrimonyTotal={current} taxes={currentTaxes} type="current" />
                        </Grid>
                        <Grid item xs={6}>
                            <SuccessionCard patrimonyTotal={future} taxes={futureTaxes} type="future" />
                        </Grid>
                    </Grid>
                </div>
            )
            }
        </>
    );
};
export default SuccessionCost;

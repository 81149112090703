import { Grid, IconButton, Typography } from '@material-ui/core';
import { ArrowBackIos, ArrowForwardIos } from '@material-ui/icons';
import { DatePicker } from '@material-ui/pickers';
import React from 'react';
import formatter from '../../../global/formater';
import FIGCard from '../FIGCard/FIGCard';
import { IYearlyGoals } from '../types';
import { useStyles } from './style';

interface IYearlyGoalProps {
    YGoals: IYearlyGoals[];
    selectedYear: number;
    fetchYear: (year: number) => void;
    yearControl: (forward: boolean, year?: number) => void;
    lastYear: number;
    planningYear: number;
}

const YearlyGoals: React.FC<IYearlyGoalProps> = ({
    YGoals,
    selectedYear,
    fetchYear,
    yearControl,
    lastYear,
    planningYear,
}) => {
    const classes = useStyles();

    const [selectedYearGoals, setSelectedYearGoals] = React.useState(YGoals[selectedYear] || YGoals[0]);
    const [goalPercentage, setGoalPercentage] = React.useState(0);

    React.useEffect(() => {
        const year = YGoals[selectedYear] || YGoals[0];
        const percentage = (year.yearAmount / selectedYearGoals.yearLeftovers) * 100;
        setGoalPercentage(isNaN(percentage) ? 0 : percentage);
        setSelectedYearGoals(year);
    }, [selectedYear, setSelectedYearGoals, YGoals, setGoalPercentage]);

    const [date, setDate] = React.useState(new Date(planningYear, 1));

    React.useEffect(() => {
        setDate(new Date(planningYear + selectedYear, 1));
    }, [planningYear, selectedYear, setDate]);

    const handleDateChange = (selectDate: Date) => {
        setDate(selectDate);
        yearControl(false, selectDate.getFullYear());
    };

    return (
        <div style={{ margin: '2vh' }}>
            <div className={classes.flex}>
                <Typography className={classes.subHeading} variant="h6">Meta anual</Typography>
                <div className={classes.grow} />
                <div className={classes.flex}>
                    <Typography>Ano</Typography>
                    <DatePicker
                        views={['year']}
                        minDate={new Date(planningYear, 1)}
                        maxDate={new Date(lastYear, 1)}
                        value={date}
                        onChange={selectDate => handleDateChange(selectDate as Date)}
                        size="small"
                        inputProps={{ style: { textAlign: 'center' } }}
                        style={{ maxWidth: '15%' }}
                    />
                    {/* @ts-ignore */}
                    <IconButton onClick={() => yearControl(false)}><ArrowBackIos /></IconButton>
                    {/* @ts-ignore */}
                    <IconButton onClick={() => yearControl(true)}><ArrowForwardIos /></IconButton>
                </div>
            </div>
            
            <Grid container spacing={2} justify="space-between">
                <Grid item xs={4}>
                    <FIGCard
                        header={`Valor acumulado em ${selectedYearGoals.year}`}
                        value={formatter.format(selectedYearGoals.yearAmount)}
                        subtitle="ESTE ANO"
                    />
                </Grid>
                <Grid item xs={4}>
                    <FIGCard
                        header="% em relação à meta"
                        value={`${goalPercentage.toFixed(2)}% concluído`}
                        subtitle=" "
                    />
                </Grid>
                <Grid item xs={4}>
                    <FIGCard
                        header="Total de aportes no ano"
                        value={formatter.format(selectedYearGoals.yearLeftovers)}
                        subtitle="ENTRADA OU SAÍDA DE CAPITAL"
                        negative
                        color={selectedYearGoals.yearLeftovers > 0 ? 'positive' : 'negative'}
                    />
                </Grid>
            </Grid>
        </div>
    );
};
export default YearlyGoals;
import { FormControl, Typography } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import CreatableSelect from 'react-select/creatable';
import api from '../../../../apis/dreamrichapi';
import { ChangeRentability } from '../../../../redux/actions/customerActions';
import { defaultProfileGain, IFinancialInfo } from '../../../toolbarInfo/types';
import { useStyles } from './style'

interface IProfileSelectorProps { rentability?: number; userID?: string; userRentability?: number; fetchFinancial: (rentability: number) => any; }

const ProfileSelector: React.FC<IProfileSelectorProps> = ({ userID, userRentability, fetchFinancial, rentability }) => {
    const classes = useStyles();
    const dispatch = useDispatch();

    const parse = (value: string) => {
        const floatstr = (value as string).replace(/\./g, '').replace(',', '.');
        return parseFloat(floatstr);
    };

    const handleInputChange = (value: any, actionMeta: any) => {
        setInputValue(value)
    };

    const chooseSelected = (): { label: string; value: number } => {
        if (!userRentability) {
            return options[1];
        }

        const option = options.filter(item => item.value === userRentability);

        if (!option.length) {
            localStorage.setItem('rentPlaceholder_'+userID, `Rentabilidade ${(userRentability * 100).toFixed(2)}% a.a`);
            return { label: `Rentabilidade  ${(userRentability * 100).toFixed(2)}% a.a`, value: userRentability };
        }

        localStorage.setItem('rentPlaceholder_'+userID, `Rentabilidade ${(userRentability * 100).toFixed(2)}% a.a`);
        return option[0];
    };

    const saveUserRentability = (rent: number) => api.post('/customerrentability', { userID, rentability: rent });

    const handleChange = (newValue: any, actionMeta: any) => {
        if (newValue) {
            // case user hasEntered rentability
            if (typeof newValue.value === 'string') {
                const parsed = parse(newValue.value);
                if (Number.isNaN(parsed)) {
                    setInputValue('');
                    return;
                }
                const parsedValue = parsed / 100.0;
                saveUserRentability(parsedValue);

                const createdValue = { value: parsedValue, label: `Rentabilidade ${inputValue}% a.a` };
                localStorage.setItem('rentPlaceholder_'+userID, `Rentabilidade ${inputValue}% a.a`);

                setPlaceholder(`Rentabilidade ${inputValue}% a.a`);                
                setOptions(currOpt => [...currOpt, createdValue]);
                setSelected(createdValue);
                dispatch(ChangeRentability(parsedValue));
            } else {
                setSelected(newValue);
                saveUserRentability(newValue.value);
                dispatch(ChangeRentability(newValue.value));
            }
        } else {
            setSelected({ label: '', value: 0 });
            setInputValue('');
        }
    };

    const [financialInfo, setFinancialInfo] = useState<IFinancialInfo>({
        cdi: 0,
        ipca: 0,
        realGain: 0,
        profileGain: defaultProfileGain,
        lastRefresh: new Date(),
    });

    const [options, setOptions] = useState([
        {
            label: `Conservador (${financialInfo.profileGain.conservative * 100}% a.a.)`,
            value: financialInfo.profileGain.conservative,
        },
        {
            label: `Moderado (${financialInfo.profileGain.moderate * 100}% a.a.)`,
            value: financialInfo.profileGain.moderate,
        },
        {
            label: `Moderado Agressivo (${financialInfo.profileGain.bold * 100}% a.a.)`,
            value: financialInfo.profileGain.bold,
        },
        {
            label: `Agressivo (${financialInfo.profileGain.agressive * 100}% a.a.)`,
            value: financialInfo.profileGain.agressive,
        },
    ]);

    const [inputValue, setInputValue] = useState('');
    const [profile, setProfile] = useState(userRentability || 4);
    const [selected, setSelected] = useState(chooseSelected());
    const [placeholder, setPlaceholder] = useState(localStorage.getItem('rentPlaceholder_'+userID) || options[0].label)

    useEffect(() => {
        if (!profile) {
            const rent = (userRentability || 0) > 0 ? userRentability! : financialInfo.profileGain.default;
            setProfile(rent);
            dispatch(ChangeRentability(rent));
        }
    }, [financialInfo]);

    useEffect(() => { chooseSelected() }, [userRentability]);

    useEffect(() => {
        if (selected) fetchFinancial(selected.value);
    }, [selected]);

    useEffect(() => {
        setTimeout(() => {
            handleChange(3, null)
        }, 300)
    }, [])

    return (
        <>
            <FormControl className={classes.formControl}>
                <Typography color="primary" noWrap style={{ marginRight: '8px' }}>Rentabilidade (ganho real) projetada</Typography>
                <CreatableSelect
                    isClearable
                    value={selected}
                    placeholder={placeholder}
                    onChange={handleChange}
                    inputValue={inputValue}
                    onInputChange={handleInputChange}
                    options={options}
                    className={classes.select}
                    onKeyDown={key => {
                        const code = key.nativeEvent.code;
                        // allow control keys
                        if (code === 'Comma' || code === 'NumpadComma') {
                            if (inputValue === '') {
                                setInputValue('0,');
                            }
                            return;
                        }
                        const allowedKeys = [
                            'NumpadDecimal',
                            'Backspace',
                            'Delete',
                            'Enter',
                            'NumpadDecimal',
                            'NumpadEnter',
                        ];
                        if (code.startsWith('Arrow') || allowedKeys.includes(code)) {
                            return;
                        }
                        if (!(code.match(/Digit\d/) || code.match(/Numpad\d/))) {
                            key.preventDefault();
                        }
                    }}
                    formatCreateLabel={input => {
                        return `Rentabilidade ${input}% a.a`;
                    }}
                />
            </FormControl>
        </>
    );
};

export default ProfileSelector;

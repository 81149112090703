import { createStyles, makeStyles, Theme } from '@material-ui/core';
import { blueGrey } from '@material-ui/core/colors';

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        cardContent: {
            padding: theme.spacing(2),
            marginTop: '1.5rem !important',
        },
        patrimonyTotal: {
            paddingTop: theme.spacing(2),
            display: 'flex',
            justifyContent: 'space-between',
        },
        percentageInfo: {
            margin: `0 ${theme.spacing(2)}px`,
            padding: `4px 6px`,
            background: blueGrey[50],
            borderRadius: 5,
        },
        total: {
            paddingTop: theme.spacing(2),
            display: 'flex',
            alignItems: 'baseline',
            justifyContent: 'space-between',
            paddingLeft: theme.spacing(2),
        },
        flexLine: {
            display: 'flex',
            marginTop: theme.spacing(1),
            alignItems: 'baseline',
            justifyContent: 'space-around',
        },
        grow: {
            flexGrow: 1,
        },
        divider: {
            marginTop: theme.spacing(1),
            marginLeft: '70%',
        },
        bold: {
            fontWeight: 600,
        },
    })
);